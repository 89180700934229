import { getDaysInMonth, getMonth, lastDayOfMonth, setDate, setMonth, setYear } from 'date-fns';
import en from 'date-fns/locale/en-GB';
import ru from 'date-fns/locale/ru';
import { LANGUAGES } from '../../../shared/constants/Languages';
import { useInject } from '../../hooks/useInject';
import { Types } from '../../inversify/types';
import { TranslationService } from '../../services/translation/TranslationService';

export class DateHelper {
	private static readonly _locales = { [LANGUAGES.ru]: ru, [LANGUAGES.en]: en };

	public static getLocale(): Locale {
		const translationService = useInject<TranslationService>(Types.TranslationService);

		return DateHelper._locales[translationService.currentLang];
	}

	public static getMonth(date: Date): number {
		return getMonth(date) + 1;
	}

	public static setMonth(date: Date, month: number): Date {
		return setMonth(date, month - 1);
	}

	public static changeDateToSelectedMonth(date: Date, targetYear: number, targetMonth: number): Date {
		const newDate = setYear(DateHelper.setMonth(new Date(), targetMonth), targetYear);
		const targetDay = date.getDate();
		const daysInMonth = getDaysInMonth(newDate);

		if (targetDay <= daysInMonth) {
			return setDate(newDate, targetDay);
		}

		return lastDayOfMonth(newDate);
	}

	public static getReportStartDate(date: Date): Date {
		return new Date(date.getFullYear(), date.getMonth(), date.getDate());
	}

	public static getReportEndDate(date: Date): Date {
		return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
	}
}
