import { EUserRole } from '../../dto/enum/EUserRole';
import { AccessRuleService } from './AccessRuleService';

export class AnalysisBiddingClaimAccessRuleService extends AccessRuleService {
	protected _canViewRoles = [
		EUserRole.VolmaAdministrator,
		EUserRole.VolmaMaterialSupport,
		EUserRole.VolmaPurchasingManager,
		EUserRole.VolmaPurchaseServiceManager,
		EUserRole.VolmaFinancialControlOfficer,
	];
	protected _canCreateRoles = [];
	protected _canDeleteRoles = [];
	protected _canEditRoles = [
		EUserRole.VolmaAdministrator,
		EUserRole.VolmaMaterialSupport,
		EUserRole.VolmaPurchasingManager,
		EUserRole.VolmaPurchaseServiceManager,
	];
}
