import i18next, { i18n, Resource } from 'i18next';
import { injectable } from 'inversify';
import { action, observable } from 'mobx';
import { initReactI18next } from 'react-i18next';
import { LANGUAGES } from '../../../shared/constants/Languages';
import { REGULAR_EXPRESSIONS } from '../../../shared/constants/RegularExpressions';
import { LocaleApi } from '../../api/LocaleApi';
import { LocaleDictionaryItemDto } from '../../dto/Locale/LocaleDictionaryItemDto';
import { LocaleEditorDto } from '../../dto/Locale/LocaleEditorDto';
import { useInject } from '../../hooks/useInject';
import { Types } from '../../inversify/types';
import { DateTimeFormatter } from '../../utils/formatters/DateTimeFormatter';
import { NumberFormatter } from '../../utils/formatters/NumberFormatter';
import { ValidationService } from '../validation/ValidationService';
import { ObservableTranslator } from './ObservableTranslator';

type Locale = { id: string; name: string };

@injectable()
export class TranslationService {
	@observable public observableTranslator = new ObservableTranslator(this.translate);
	@observable public currentLang = '';

	private storageLngKey = 'language';
	private cookieLngKey = 'VolmaTrading.Locale';
	private availableLanguages: Array<Locale> = [];
	private localeApi = useInject<LocaleApi>(Types.LocaleApi);

	public async toggleLanguageByStorage(): Promise<void> {
		this.currentLang === LANGUAGES.ru ? await this.setLanguage(LANGUAGES.en) : await this.setLanguage(LANGUAGES.ru);
	}

	public getAvailableLanguages(): Array<Locale> {
		return this.availableLanguages;
	}

	private translate(value: string, params?: Record<string, unknown>): string {
		return i18next.t(value, { ...params, lng: this.currentLang || LANGUAGES.ru });
	}

	private async setLanguage(language: string): Promise<void> {
		localStorage.setItem(this.storageLngKey, language);
		document.cookie = `${this.cookieLngKey}=${language};path=/;`;
		this.setLng(language);
		await i18next.changeLanguage(language);
		this.setObservableTranslator(new ObservableTranslator((value, params) => this.translate(value, params)));
		useInject<ValidationService>(Types.ValidationService).setLocale();
	}

	@action
	private setLng(str: string): void {
		this.currentLang = str;
	}

	@action
	private setObservableTranslator(translator: ObservableTranslator): void {
		this.observableTranslator = translator;
	}

	private formatInterpolatedValue(
		value: string | number,
		format: string | undefined,
		lng: string | undefined,
	): string {
		const formattedValue = typeof value === 'number' ? NumberFormatter.getLocalizedNumberString(value) : value;

		if (REGULAR_EXPRESSIONS.isoDate.test(formattedValue)) {
			return DateTimeFormatter.getDateTimeWithSecondsString(new Date(value));
		}

		return formattedValue;
	}

	public async init(): Promise<i18n> {
		const localesTable = (await this.localeApi.getDictionary<LocaleDictionaryItemDto>()).data.items;
		return new Promise((resolve) => {
			let defaultLng = '';
			const localResources: Resource = {};
			const namespace = 'common';

			const localizationPromises = localesTable.map(async (locale) => {
				this.availableLanguages.push({
					id: locale.id,
					name: locale.name,
				});
				if (locale.default) {
					defaultLng = locale.name;
				}
				localResources[locale.name] = {};
				const keysArray: { [key: string]: string } = {};
				return this.localeApi.getRecord<LocaleEditorDto>(locale.id).then((res) => {
					res.data.items.forEach((editorItem) => (keysArray[editorItem.key] = editorItem.value));
					localResources[locale.name][namespace] = { ...keysArray };
					localResources[locale.name] = { ...localResources[locale.name] };
				});
			});
			Promise.all(localizationPromises).then(async () => {
				await i18next.use(initReactI18next).init({
					resources: { ...localResources },
					fallbackLng: 'dev',
					defaultNS: namespace,
					keySeparator: false,
					debug: process.env['NODE_ENV'] === 'development',
					interpolation: {
						escapeValue: false,
						formatSeparator: ',',
						useRawValueToEscape: true,
						alwaysFormat: true,
						prefix: '{',
						suffix: '}',
						format: this.formatInterpolatedValue,
					},
				});
				const lng = localStorage.getItem(this.storageLngKey);
				lng ? this.setLanguage(lng) : this.setLanguage(defaultLng);
				resolve(i18next);
			});
		});
	}
}
