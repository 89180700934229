import { ENeedWorkflowState } from '../../../dto/enum/ENeedWorkflowState';
import { BaseWorkflowStateOrderer } from '../BaseWorkflowStateOrderer';

export class NeedWorkflowStateOrderer extends BaseWorkflowStateOrderer {
	protected _ordinaryStates = [
		ENeedWorkflowState.Draft,
		ENeedWorkflowState.Revision,
		ENeedWorkflowState.New,
		ENeedWorkflowState.TermsAndPricesIndicated,
		ENeedWorkflowState.ApprovedByEngineer,
		ENeedWorkflowState.ApprovedByDirector,
		ENeedWorkflowState.InProgress,
	];

	protected _terminatedStates = [ENeedWorkflowState.Cancelled, ENeedWorkflowState.Refusal];
}
