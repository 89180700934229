import { EUserRole } from '../../dto/enum/EUserRole';
import { AccessRuleService } from './AccessRuleService';

export class MaterialGroupAccessRuleService extends AccessRuleService {
	protected _canViewRoles = [this.roleService.getAllRolesMaskExcluding([EUserRole.VolmaService])];
	protected _canCreateRoles = [];
	protected _canDeleteRoles = [];
	protected _canEditRoles = [
		EUserRole.VolmaMaterialSupport,
		EUserRole.VolmaEconomist,
		EUserRole.VolmaAdministrator,
		EUserRole.VolmaPurchasingManager,
		EUserRole.VolmaPurchaseServiceManager,
		EUserRole.VolmaService,
	];
}
