import { useInject } from '../../../hooks/useInject';
import { Types } from '../../../inversify/types';
import { MoveLimitsAccessRuleService } from '../../accessRule/MoveLimitsAccessRuleService';
import { BaseApprover } from '../BaseApprover';

export class MoveLimitApprover extends BaseApprover {
	private readonly _moveLimitsAccessRuleService = useInject<MoveLimitsAccessRuleService>(
		Types.MoveLimitsAccessRuleService,
	);

	public canMoveLimit = (): boolean => {
		const hasAcceptableRole = this._moveLimitsAccessRuleService.canView();

		return hasAcceptableRole;
	};
}
