import { AxiosPromise } from 'axios';
import { MaterialBalanceDto } from '../dto/Material/MaterialBalanceDto';
import { MaterialPriceDto } from '../dto/Material/MaterialPriceDto';
import { TableApi } from './TableApi';

export class MaterialApi extends TableApi {
	protected tableUrl: string = this._urlFabric.materialTable;

	public getSAPMaterialPrice(materialId: string, factoryId: string): AxiosPromise<MaterialPriceDto> {
		const url = this._urlFabric.getSAPMaterialPrice(materialId, factoryId);

		return this.get(url);
	}

	public getSAPMaterialBalance(materialId: string, factoryId: string): AxiosPromise<MaterialBalanceDto> {
		const url = this._urlFabric.getSAPMaterialBalance(materialId, factoryId);

		return this.get(url);
	}
}
