import { EOrderWorkflowState } from '../../../dto/enum/EOrderWorkflowState';
import { BaseWorkflowStateOrderer } from '../BaseWorkflowStateOrderer';

export class OrderWorkflowStateOrderer extends BaseWorkflowStateOrderer {
	protected _ordinaryStates = [
		EOrderWorkflowState.Draft,
		EOrderWorkflowState.Revision,
		EOrderWorkflowState.VolmaAgreement,
		EOrderWorkflowState.SupplierAgreement,
		EOrderWorkflowState.Paperwork,
		EOrderWorkflowState.Delivering,
		EOrderWorkflowState.Closed,
	];

	protected _terminatedStates = [EOrderWorkflowState.SapOrderError, EOrderWorkflowState.Cancelled];

	private readonly _previousStateMap: Record<number, EOrderWorkflowState> = {
		[EOrderWorkflowState.Cancelled]: EOrderWorkflowState.Draft,
		[EOrderWorkflowState.SapOrderError]: EOrderWorkflowState.Paperwork,
	};

	public getPreviousWorkflowState = (state: number): EOrderWorkflowState =>
		this._previousStateMap[state] || EOrderWorkflowState.Draft;
}
