import { AxiosPromise } from 'axios';
import { AgreeMultipleNeedDto } from '../dto/Need/AgreeMultipleNeedDto';
import { CopyMultipleNeedDto } from '../dto/Need/CopyMultipleNeedDto';
import { NeedTableItemDto } from '../dto/Need/NeedTableItemDto';
import { NeedVolmaMaterialSupportDto } from '../dto/Need/NeedVolmaMaterialSupportDto';
import { SendToReviewMultipleNeedDto } from '../dto/Need/SendToReviewMultipleNeedDto';
import { BatchOperationResultDto } from '../dto/Special/BatchOperationResultDto';
import { DictionaryDto } from '../dto/Special/DictionaryDto';
import { DisagreeNeedDto } from './../dto/Need/DisagreeNeedDto';
import { TableApi } from './TableApi';

export class NeedApi extends TableApi {
	protected tableUrl: string = this._urlFabric.needTable;

	public fillNeed(needId: string, dto: NeedVolmaMaterialSupportDto): AxiosPromise<void> {
		const url = this._urlFabric.fillNeed(needId);

		return this.post(url, dto);
	}

	public getRefusedNeeds(year: number, month: number): AxiosPromise<DictionaryDto<NeedTableItemDto>> {
		const url = this._urlFabric.getRefusedNeeds(year, month);

		return this.get(url, {});
	}

	public sendNeedToReview(dto: DisagreeNeedDto): AxiosPromise<void> {
		const url = this._urlFabric.sendNeedToReview(dto.needId);

		return this.post(url, dto);
	}

	public cancelNeed(needId: string): AxiosPromise<void> {
		const url = this._urlFabric.cancelNeed(needId);

		return this.post(url, {});
	}

	public agreeNeed(needId: string): AxiosPromise<void> {
		const url = this._urlFabric.agreeNeed(needId);

		return this.post(url, {});
	}

	public batchAgreeNeeds(dto: AgreeMultipleNeedDto): AxiosPromise<BatchOperationResultDto> {
		const url = this._urlFabric.batchAgreeNeeds();

		return this.post(url, dto);
	}

	public batchSendToReviewNeeds(dto: SendToReviewMultipleNeedDto): AxiosPromise<BatchOperationResultDto> {
		const url = this._urlFabric.batchSendToReviewNeeds();

		return this.post(url, dto);
	}

	public batchCopyNeeds(dto: CopyMultipleNeedDto): AxiosPromise<BatchOperationResultDto> {
		const url = this._urlFabric.batchCopyNeeds();

		return this.post(url, dto);
	}
}
