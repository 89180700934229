import { injectable } from 'inversify';

@injectable()
export abstract class BaseWorkflowStateOrderer {
	protected abstract readonly _ordinaryStates: number[];
	protected abstract readonly _terminatedStates: number[];

	public get ordinaryStates(): number[] {
		return this._ordinaryStates;
	}

	public get terminatedStates(): number[] {
		return this._terminatedStates;
	}
}
