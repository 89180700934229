import { BidOfferMaterialEditorTableItemDto } from '../../../models/BidOffer/BidOfferMaterialEditorTableItemDto';
import { BaseApprover } from '../BaseApprover';

export class BidOfferApprover extends BaseApprover {
	public shouldUpdateScheduleButtonBeDisabled = (x: BidOfferMaterialEditorTableItemDto): boolean => {
		return x.offer.isAgree;
	};

	public shouldResetButtonBeVisible = (x: BidOfferMaterialEditorTableItemDto): boolean => {
		return x.offer.isModified;
	};
}
