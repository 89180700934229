import { EUserRole } from '../../dto/enum/EUserRole';
import { AccessRuleService } from './AccessRuleService';

export class FactoryAccessRuleService extends AccessRuleService {
	protected _canViewRoles = [
		this.roleService.getAllRolesMaskExcluding([
			EUserRole.VolmaService,
			EUserRole.CustomerContactPerson,
			EUserRole.CustomerEmployee,
		]),
	];
	protected _canCreateRoles = [];
	protected _canDeleteRoles = [];
	protected _canEditRoles = [EUserRole.VolmaService, EUserRole.VolmaAdministrator];
}
