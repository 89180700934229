import { AxiosPromise } from 'axios';
import { FinancialManagementUnitLimitRequestDto } from '../models/FinancialManagementUnit/FinancialManagementUnitLimitRequestDto';
import { FinancialManagementUnitLimitEditorDto } from './../dto/FinancialUnitLimit/FinancialManagementUnitLimitEditorDto';
import { TableApi } from './TableApi';
export class FinancialManagementUnitApi extends TableApi {
	protected tableUrl: string = this._urlFabric.financialManagementUnitsTable;

	public getFinancialManagementUnitLimits(
		financialUnitId: string,
		dto: FinancialManagementUnitLimitRequestDto,
	): AxiosPromise<FinancialManagementUnitLimitEditorDto> {
		const url = this._urlFabric.getFinancialManagementUnitsLimits(financialUnitId, dto.year, dto.month);

		return this.get(url);
	}
}
