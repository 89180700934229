import { EUserRole } from '../../dto/enum/EUserRole';
import { AccessRuleService } from './AccessRuleService';

export class MaterialAccessRuleService extends AccessRuleService {
	protected _canViewRoles = [this.roleService.getAllRolesMaskExcluding([EUserRole.VolmaService])];
	protected _canCreateRoles = [];
	protected _canDeleteRoles = [];
	protected _canEditRoles = [
		EUserRole.VolmaMaterialSupport,
		EUserRole.VolmaEconomist,
		EUserRole.VolmaAdministrator,
		EUserRole.VolmaLogist,
		EUserRole.VolmaTenderOfficer,
		EUserRole.VolmaEconomicProtectionOfficer,
		EUserRole.VolmaService,
		EUserRole.VolmaMaterialDictionaryEditor,
		EUserRole.VolmaFinancialControlOfficer,
	];
}
