import { AxiosPromise } from 'axios';
import { ComplaintEditorDto } from '../dto/Complaint/ComplaintEditorDto';
import { ComplaintTableItemDto } from '../dto/Complaint/ComplaintTableItemDto';
import { TableDto } from '../dto/Special/TableDto';
import { BlockSupplierDto } from '../dto/Supplier/BlockSupplierDto';
import { CreateSupplierDto } from '../dto/Supplier/CreateSupplierDto';
import { SetSapCodeSupplierDto } from '../dto/Supplier/SetSapCodeSupplierDto';
import { SetSupplierMetricsDto } from '../dto/Supplier/SetSupplierMetricsDto';
import { TableApi } from './TableApi';

export class SupplierApi extends TableApi {
	protected tableUrl: string = this._urlFabric.supplierTable;

	public register(dto: CreateSupplierDto): AxiosPromise<void> {
		const url = this._urlFabric.registerSupplier();

		return this.post(url, dto);
	}

	public setSapSupplierCode(supplierId: string, dto: SetSapCodeSupplierDto): AxiosPromise<void> {
		const url = this._urlFabric.setSapSupplierCode(supplierId);

		return this.post(url, dto);
	}

	public setSupplierMetrics(supplierId: string, dto: SetSupplierMetricsDto): AxiosPromise<void> {
		const url = this._urlFabric.setSupplierMetrics(supplierId);

		return this.put(url, dto);
	}

	public getSupplierComplaints(supplierId: string): AxiosPromise<TableDto<ComplaintTableItemDto>> {
		const url = this._urlFabric.getSupplierComplaints(supplierId);

		return this.get(url);
	}

	public createComplaint(dto: ComplaintEditorDto): AxiosPromise<void> {
		const url = this._urlFabric.createComplaint();

		return this.post(url, dto);
	}

	public blockSupplier(supplierId: string, dto: BlockSupplierDto): AxiosPromise<void> {
		const url = this._urlFabric.blockSupplier(supplierId);

		return this.post(url, dto);
	}

	public unblockSupplier(supplierId: string): AxiosPromise<void> {
		const url = this._urlFabric.unblockSupplier(supplierId);

		return this.post(url, {});
	}

	public restrictSupplier(supplierId: string): AxiosPromise<void> {
		const url = this._urlFabric.restrictSupplier(supplierId);

		return this.post(url, {});
	}
}
