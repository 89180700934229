import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { injectable } from 'inversify';
import { LoginDto } from '../dto/Login/LoginDto';
import { AuthenticationResponseDto } from '../models/Auth/AuthenticationResponseDto';
import { RefreshTokenAuthenticationDto } from '../models/Auth/RefreshTokenAuthenticationDto';
import { PasswordAuthenticationDto } from './../models/Auth/PasswordAuthenticationDto';
import { Api } from './Api';

@injectable()
export class AuthUserApi extends Api {
	private readonly _axiosOptions: AxiosRequestConfig = {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	};
	private readonly _clientId = 'volma-trading-spa';

	private _generateFormDataPayload = (formData: any): string => {
		return Object.entries(formData)
			.map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value as any))
			.join('&');
	};

	public logIn(loginDto: LoginDto): AxiosPromise<AuthenticationResponseDto> {
		const formData: PasswordAuthenticationDto = {
			...loginDto,
			grant_type: 'password',
			client_id: this._clientId,
		};

		const payload = this._generateFormDataPayload(formData);

		return this.post<AuthenticationResponseDto>(this._urlFabric.logIn(), payload, this._axiosOptions);
	}

	public refreshToken(refreshToken: string): AxiosPromise<AuthenticationResponseDto> {
		const formData: RefreshTokenAuthenticationDto = {
			grant_type: 'refresh_token',
			refresh_token: refreshToken,
			client_id: this._clientId,
		};

		const payload = this._generateFormDataPayload(formData);

		return this.post<AuthenticationResponseDto>(this._urlFabric.refreshToken(), payload, this._axiosOptions);
	}
}
