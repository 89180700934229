import { AxiosPromise } from 'axios';
import { FactoryLimitRequestDto } from '../models/FactoryLimit/FactoryLimitRequestDto';
import { FactoryLimitEditorDto } from './../dto/FactoryLimit/FactoryLimitEditorDto';
import { TableApi } from './TableApi';

export class FactoryApi extends TableApi {
	protected tableUrl: string = this._urlFabric.factoryTable;

	public getFactoryLimits(factoryId: string, dto: FactoryLimitRequestDto): AxiosPromise<FactoryLimitEditorDto> {
		const url = this._urlFabric.getFactoryLimits(factoryId, dto);

		return this.get(url);
	}
}
