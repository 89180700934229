import { Either, left, right } from '@sweet-monads/either';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { FileApi } from './../../api/FileApi';
import { BlobDto } from './../../dto/Blob/BlobDto';
import { useInject } from './../../hooks/useInject';
import { Types } from './../../inversify/types';
import { NotificationService } from './../../services/notifcation/notificationService';
import { ApiHelper } from './../../utils/helpers/ApiHelper';
import { FormDataHelper } from './../../utils/helpers/FormDataHelper';
import { translate } from './../../utils/translate';
import { IUploadFile } from './IUploadFile';

@injectable()
export class FileService {
	private readonly _chunkSizeBytes = 10 ** 6; // 1mb
	private readonly _contentType = 'multipart/form-data';
	private readonly _fileFormDataName = 'data';

	private readonly _notificationService = useInject<NotificationService>(Types.NotificationService);
	private readonly _fileApi: FileApi = useInject<FileApi>(Types.FileApi);
	private readonly _apiHelper: ApiHelper = new ApiHelper();

	get contentType(): string {
		return this._contentType;
	}

	private _getFileId = async (params: IUploadFile): Promise<string> => {
		const fileData = await this._fileApi.createFile({
			filename: params.file.name,
			contentType: this._contentType,
			publicAccess: params.isPublicAccess,
		});

		return fileData.data;
	};

	private _onUploadProgressChanged = (currentLoadedBytes: number, totalLoadedBytes: number, params: IUploadFile) => {
		const totalLoaded = (currentLoadedBytes + totalLoadedBytes) * 100;
		const percentCompleted = Math.round(totalLoaded / params.file.size);

		params.setProgress(percentCompleted);
	};

	private _storeFile = async (fileId: string, params: IUploadFile): Promise<void> => {
		const file = params.file;

		for (let loadedBytes = 0; loadedBytes < file.size; loadedBytes += this._chunkSizeBytes) {
			const chunk = file.slice(loadedBytes, loadedBytes + this._chunkSizeBytes + 1);
			const formData = FormDataHelper.wrapBlobData(this._fileFormDataName, chunk);

			await this._fileApi.store({
				id: fileId,
				chunk: formData,
				onProgressChanged: (progressEvent) =>
					this._onUploadProgressChanged(progressEvent.loaded, loadedBytes, params),
			});
		}
	};

	private _finishUploadFile = async (fileId: string): Promise<void> => {
		await this._fileApi.finish(fileId);
	};

	public getFileSrcUrl = (id: string): string => {
		return this._fileApi.getFileSrcUrl(id);
	};

	public downloadFileBlob = async (
		dto: BlobDto,
		sourceGetter = this.getFileSrcUrl,
	): Promise<Either<AxiosResponse<void>, Blob>> => {
		const request = this._fileApi.getFileBlob(sourceGetter(dto.id));
		const data = await this._apiHelper.doApiRequest(request);

		if (data.isRight()) {
			const link = document.createElement('a');
			link.download = dto.name;

			const blob = new Blob([data.value], { type: dto.contentType });

			link.href = URL.createObjectURL(blob);
			link.click();

			URL.revokeObjectURL(link.href);
		}

		return data;
	};

	public uploadFileFacade = async (params: IUploadFile): Promise<Either<any, string>> => {
		params.setProgress(0);

		try {
			const fileId = await this._getFileId(params);
			await this._storeFile(fileId, params);
			await this._finishUploadFile(fileId);

			return right(fileId);
		} catch (e) {
			console.error(e);

			this._notificationService.createNotification(
				NotificationService.errorNotificationType,
				translate('notification.errorfile'),
			);

			return left(e);
		}
	};
}
