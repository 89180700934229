import { AxiosPromise } from 'axios';
import { UserDto } from './../dto/User/UserDto';
import { TableApi } from './TableApi';
export class UserApi extends TableApi {
	protected tableUrl: string = this._urlFabric.userTable;

	public getCurrentUser = (): AxiosPromise<UserDto> => {
		return this.get(this._urlFabric.getCurrentUser());
	};
}
