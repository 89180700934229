import { LANGUAGES } from '../../shared/constants/Languages';
import { Kernel } from '../inversify/inversify.config';
import { Types } from '../inversify/types';
import { TranslationService } from '../services/translation/TranslationService';

export function translate(key: string, params?: Record<string, unknown>): string {
	const translationService = Kernel.get<TranslationService>(Types.TranslationService);
	return translationService.observableTranslator.translate(key, params);
}

export function langRelevantPropName<T>(name: keyof T, nameEng: keyof T): keyof T {
	const translationService = Kernel.get<TranslationService>(Types.TranslationService);
	return translationService.currentLang === LANGUAGES.ru ? name : nameEng;
}

export function nestedLangRelevantPropName<T, P>(name: keyof T, nestedName: keyof P, nestedNameEng: keyof P): string {
	return `${name}.${langRelevantPropName<P>(nestedName, nestedNameEng)}`;
}

export function langRelevantProp<T>(prop: T, propEng?: T): T {
	const translationService = Kernel.get<TranslationService>(Types.TranslationService);
	return translationService.currentLang === LANGUAGES.ru ? prop : propEng || prop;
}
