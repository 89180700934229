export class ArrayHelper {
	public static getArrayFieldName = (name: string, index: number): string => {
		return `${name}.${index}`;
	};

	public static mapReverse<T = any>(arr: T[], fn: (item: T, index: number) => any): any[] {
		return arr.reduceRight<T[]>((acc, item, index) => {
			acc.push(fn(item, index));

			return acc;
		}, []);
	}

	public static joinArrayWithoutLastSeparator(arr: any[]): string {
		const last = arr.pop();

		return arr.join(', ') + last;
	}

	public static mergeArraysWithoutDuplicates<T>(a: T[], b: T[]): T[] {
		return Array.from(new Set([...a, ...b]));
	}
}
