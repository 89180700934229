import { AxiosPromise } from 'axios';
import { EReportType } from '../dto/enum/EReportType';
import { useInject } from '../hooks/useInject';
import { BlobDto } from './../dto/Blob/BlobDto';
import { Types } from './../inversify/types';
import { FileApi } from './FileApi';
import { TableApi } from './TableApi';

export class ReportApi extends TableApi {
	protected readonly _fileApi: FileApi = useInject<FileApi>(Types.FileApi);
	protected tableUrl: string = this._urlFabric.reportTable;

	public getReportParameters<T>(dto: T, type: EReportType): AxiosPromise<BlobDto> {
		const url = this._urlFabric.getReportParameters(type);

		return this.post(url, dto);
	}

	public getReportUrl(type: EReportType, parameterId: string): string {
		const url = this._urlFabric.getReportUrl(type, parameterId);

		return url;
	}
}
