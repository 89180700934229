import { ENeedWorkflowState } from '../../dto/enum/ENeedWorkflowState';
import { EUserRole } from '../../dto/enum/EUserRole';
import { AccessRuleService } from './AccessRuleService';

export class NeedAccessRuleService extends AccessRuleService {
	protected _canViewRoles = [
		EUserRole.VolmaEmployee,
		EUserRole.VolmaMaterialSupport,
		EUserRole.VolmaLeadEngineer,
		EUserRole.VolmaProductionDirector,
		EUserRole.VolmaEconomist,
		EUserRole.VolmaAdministrator,
		EUserRole.VolmaPurchasingManager,
		EUserRole.VolmaFinancialControlOfficer,
	];
	protected _canCreateRoles = [
		EUserRole.VolmaEmployee,
		EUserRole.VolmaMaterialSupport,
		EUserRole.VolmaAdministrator,
		EUserRole.VolmaPurchasingManager,
	];
	protected _canDeleteRoles = [];
	protected _canEditRoles = [
		EUserRole.VolmaEmployee,
		EUserRole.VolmaMaterialSupport,
		EUserRole.VolmaLeadEngineer,
		EUserRole.VolmaProductionDirector,
		EUserRole.VolmaAdministrator,
		EUserRole.VolmaPurchasingManager,
	];
	protected _canCopyRoles = this._canCreateRoles;
	protected readonly _canAgreeRoles = [
		EUserRole.VolmaMaterialSupport,
		EUserRole.VolmaLeadEngineer,
		EUserRole.VolmaProductionDirector,
	];
	protected readonly _canFillNeedRoles = [EUserRole.VolmaMaterialSupport];
	protected readonly _canAgreeByDirectorRoles = [EUserRole.VolmaProductionDirector];
	protected readonly _canAgreeByEngineerRoles = [EUserRole.VolmaLeadEngineer];
	protected readonly _canViewStateHistoryRoles = [
		EUserRole.VolmaEmployee,
		EUserRole.VolmaMaterialSupport,
		EUserRole.VolmaLeadEngineer,
		EUserRole.VolmaProductionDirector,
		EUserRole.VolmaEconomist,
		EUserRole.VolmaAdministrator,
		EUserRole.VolmaPurchasingManager,
	];
	private readonly _canViewReadonlyMaterialPriceRoles = [EUserRole.VolmaEmployee];

	public canSendToReviewByState = (needState: ENeedWorkflowState | undefined): boolean => {
		if (needState === ENeedWorkflowState.New && this._hasRole([EUserRole.VolmaMaterialSupport])) return true;

		if (needState === ENeedWorkflowState.TermsAndPricesIndicated && this._hasRole([EUserRole.VolmaLeadEngineer]))
			return true;

		if (needState === ENeedWorkflowState.ApprovedByEngineer && this._hasRole([EUserRole.VolmaProductionDirector]))
			return true;

		return false;
	};

	public canCopy = (): boolean => {
		return this._hasRole(this._canCopyRoles);
	};

	public canFillNeed = (): boolean => {
		return this._hasRole(this._canFillNeedRoles);
	};

	public canAgreeByDirector = (): boolean => {
		return this._hasRole(this._canAgreeByDirectorRoles);
	};

	public canAgreeByEngineer = (): boolean => {
		return this._hasRole(this._canAgreeByEngineerRoles);
	};

	public canViewHistoryStateTable = (): boolean => {
		return this._hasRole(this._canViewStateHistoryRoles);
	};

	public canViewReadonlyMaterialPriceRoles = (): boolean => {
		return this._hasRole(this._canViewReadonlyMaterialPriceRoles);
	};
}
