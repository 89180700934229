export class NumberFormatter {
	public static getFormattedNumberString = (value: number, precision = 2): string => {
		const roundedValueString = value.toFixed(precision);
		const truncatedZerosString = parseFloat(roundedValueString).toString();

		return truncatedZerosString;
	};

	public static getLocalizedNumberString = (value: number): string => {
		return value.toLocaleString(undefined, { maximumFractionDigits: 10 });
	};
}
