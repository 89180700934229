import { format } from 'date-fns';
import { DateHelper } from '../helpers/DateHelper';
import { StringHelper } from './../helpers/StringHelper';

export class DateTimeFormatter {
	public static readonly dateFormat = 'dd.MM.yyyy';
	public static readonly timeFormat = 'HH:mm';
	public static readonly dateTimeWithSecondsFormat = `${DateTimeFormatter.dateFormat} ${DateTimeFormatter.timeFormat}:ss`;
	public static readonly dateTimeFormat = `${DateTimeFormatter.dateFormat} ${DateTimeFormatter.timeFormat}`;
	public static readonly timeSpanFormat = 'HH:mm:ss';
	public static readonly yearAndMonthFormat = 'LLLL yyyy';
	public static readonly yearFormat = 'yyyy';

	public static getHoursWithMinutes(date: Date): string {
		return format(date, DateTimeFormatter.timeFormat);
	}

	public static getDateTimeWithSecondsString(date: Date): string {
		return format(date, DateTimeFormatter.dateTimeWithSecondsFormat);
	}

	public static getDateString(date: Date): string {
		return format(date, DateTimeFormatter.dateFormat);
	}

	public static getDateTimeString(date: Date): string {
		return format(date, DateTimeFormatter.dateTimeFormat);
	}

	public static getYearAndMonthString(date: Date): string {
		const formattedDate = format(date, DateTimeFormatter.yearAndMonthFormat, { locale: DateHelper.getLocale() });

		return StringHelper.getStringWithFirstLetterCapitalize(formattedDate);
	}

	public static getTimeSpan(date: Date): string {
		return format(date, DateTimeFormatter.timeSpanFormat);
	}
}
