import { BiddingClaimMaterialEditorDto } from '../../../dto/BiddingClaim/BiddingClaimMaterialEditorDto';
import { EAgreementState } from '../../../dto/enum/EAgreementState';
import { EMaterialOrderStatus } from '../../../dto/enum/EMaterialOrderStatus';
import { EOrderWorkflowState } from '../../../dto/enum/EOrderWorkflowState';
import { UserDto } from '../../../dto/User/UserDto';
import { useInject } from '../../../hooks/useInject';
import { Types } from '../../../inversify/types';
import { OrderViewExtendedDto } from '../../../models/Order/OrderViewExtendedDto';
import { OrderAccessRuleService } from '../../accessRule/OrderAccessRuleService';
import { RoleService } from '../../role/RoleService';
import { BaseApprover } from '../BaseApprover';
import { StringComparer } from './../../../utils/comparers/StringComparer';
import { EUserRole } from '../../../dto/enum/EUserRole';

export class OrderApprover extends BaseApprover {
	private readonly _orderAccessRuleService = useInject<OrderAccessRuleService>(Types.OrderAccessRuleService);
	protected readonly roleService = useInject<RoleService>(Types.RoleService);

	public canAgreeOrDisagreeOrder = (user: UserDto | undefined, record: OrderViewExtendedDto | undefined): boolean => {
		const hasAcceptableStatus = this.hasAcceptableStatus(record?.workflowState, [
			EOrderWorkflowState.VolmaAgreement,
		]);

		if (!user || !hasAcceptableStatus) {
			return false;
		}

		const unhandledRoles = record?.agreements
			.filter((x) => x.state === EAgreementState.Waiting)
			.map((x) => x.agreementRoles);

		if (!this.roleService.hasAcceptableRole(unhandledRoles as EUserRole[], user.roles)) {
			return false;
		}

		const hasAcceptableRole = this._orderAccessRuleService.canAgreeOrder(record as OrderViewExtendedDto);

		return hasAcceptableRole && this._isUserHasSameMaterialGroup(user, record as OrderViewExtendedDto);
	};

	public canSupplierAgreeOrDisagreeOrder = (
		user: UserDto | undefined,
		record: OrderViewExtendedDto | undefined,
	): boolean => {
		const hasAcceptableRole = this._orderAccessRuleService.canAgreeOrdersBySupplier();
		const hasAcceptableStatus = this.hasAcceptableStatus(record?.workflowState, [
			EOrderWorkflowState.SupplierAgreement,
		]);
		const isSupplier = StringComparer.compare(record?.supplierId, user?.supplierId);

		return isSupplier && hasAcceptableRole && hasAcceptableStatus;
	};

	private _isUserHasSameMaterialGroup(user: UserDto, record: OrderViewExtendedDto): boolean {
		if (user?.materialGroups.length === 0) {
			return true;
		}

		const assignedUserMaterialGroupsIds = user.materialGroups.map((group) => group.id);
		const orderMaterialGroupdsIds = record.materials.map((group) => group.materialGroupId);

		return orderMaterialGroupdsIds.some((group) => assignedUserMaterialGroupsIds.includes(group));
	}

	public canSendOrderToAgreement(user: UserDto | undefined, record: OrderViewExtendedDto | undefined): boolean {
		const hasAcceptableRole = this._orderAccessRuleService.canSendOrderToAgreement();
		const hasAcceptableStatus = this.hasAcceptableStatus(record?.workflowState, [
			EOrderWorkflowState.Draft,
			EOrderWorkflowState.Revision,
		]);
		const isCreator = StringComparer.compare(record?.createdById, user?.id);

		return hasAcceptableRole && isCreator && hasAcceptableStatus;
	}

	public canRetrySap(record: OrderViewExtendedDto | undefined): boolean {
		const hasAcceptableRole = this._orderAccessRuleService.canRetrySap();
		const hasAcceptableStatus = this.hasAcceptableStatus(record?.workflowState, [
			EOrderWorkflowState.SapOrderError,
		]);

		return hasAcceptableRole && hasAcceptableStatus;
	}

	public canCancelOrder(user: UserDto | undefined, record: OrderViewExtendedDto | undefined): boolean {
		const isCreator = StringComparer.compare(record?.createdById, user?.id);
		const hasAcceptableStatus = this.hasAcceptableStatus(record?.workflowState, [EOrderWorkflowState.Draft]);

		return isCreator && hasAcceptableStatus;
	}

	public canFormOrders(materials: BiddingClaimMaterialEditorDto[] | undefined): boolean {
		if (!materials) {
			return false;
		}

		const hasAcceptableRole = this._orderAccessRuleService.canCreate();

		return (
			hasAcceptableRole &&
			materials.some((material) => material.materialOrderStatus > EMaterialOrderStatus.WaitAnalysis)
		);
	}

	public canEditOrder = (
		user: UserDto | undefined,
		order:
			| {
					state: EOrderWorkflowState | undefined;
					createdById: string | undefined;
			  }
			| undefined,
	): boolean => {
		const hasAcceptableStatus = this.hasAcceptableStatus(order?.state, [
			EOrderWorkflowState.Draft,
			EOrderWorkflowState.Revision,
		]);
		const isCreator = StringComparer.compare(order?.createdById, user?.id);

		return isCreator && hasAcceptableStatus;
	};
}
