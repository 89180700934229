import { isValidString } from './../Utils';

export class StringComparer {
	public static compare = (str: string | undefined, compareStr: string | undefined): boolean => {
		if (!isValidString(str) || !isValidString(compareStr)) {
			return false;
		}

		return str === compareStr;
	};
}
