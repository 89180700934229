import { AxiosPromise } from 'axios';
import { BiddingClaimOfferDetailsItemDto } from '../dto/BidOffer/BiddingClaimOfferDetailsItemDto';
import { BidOfferItemsDto } from '../dto/BidOffer/BidOfferItemsDto';
import { TableDto } from './../dto/Special/TableDto';
import { TableApi } from './TableApi';

export class BidOfferApi extends TableApi {
	protected tableUrl: string = this._urlFabric.bidOffersTable;

	public getBidOffersByBiddingClaim(
		biddingClaimId: string,
		materialId: string,
	): AxiosPromise<TableDto<BiddingClaimOfferDetailsItemDto>> {
		const url = this._urlFabric.getBidOffersByBiddingClaim(biddingClaimId, materialId);

		return this.get(url);
	}

	public getSupplierMaterialOffers(biddingClaimId: string, supplierId: string): AxiosPromise<BidOfferItemsDto> {
		const url = this._urlFabric.getSupplierMaterialOffers(biddingClaimId, supplierId);

		return this.get(url);
	}

	protected getEditRecordUrl = (biddingClaimId: string): string => {
		const url = this._urlFabric.bidOffersCreateUpdateUrl(biddingClaimId);

		return url;
	};

	protected createRecordUrl = (biddingClaimId: string): string => {
		const url = this._urlFabric.bidOffersCreateUpdateUrl(biddingClaimId);

		return url;
	};

	protected editRecordUrl(biddingClaimId: string): string {
		const url = this._urlFabric.bidOffersCreateUpdateUrl(biddingClaimId);

		return url;
	}
}
