export class ObservableTranslator {
	private _translate: (key: string, params?: Record<string, unknown>) => string;

	constructor(translateFunc: (key: string, params?: Record<string, unknown>) => string) {
		this._translate = translateFunc;
	}

	public translate(key: string, params?: Record<string, unknown>): string {
		return this._translate(key, params);
	}
}
