import { AxiosPromise } from 'axios';
import { DisagreeOrderDto } from './../dto/Order/DisagreeOrderDto';
import { TableApi } from './TableApi';

export class OrderApi extends TableApi {
	protected tableUrl: string = this._urlFabric.orderTable;

	public sendOrderToAgreement(orderId: string): AxiosPromise<void> {
		const url = this._urlFabric.sendOrderToAgreement(orderId);

		return this.post(url, {});
	}

	public agreeOrder(orderId: string): AxiosPromise<void> {
		const url = this._urlFabric.agreeOrder(orderId);

		return this.post(url, {});
	}

	public disagreeOrder(dto: DisagreeOrderDto): AxiosPromise<void> {
		const url = this._urlFabric.disagreeOrder(dto.orderId);

		return this.post(url, dto);
	}

	public cancelOrder(orderId: string): AxiosPromise<void> {
		const url = this._urlFabric.cancelOrder(orderId);

		return this.post(url, {});
	}

	public retrySap(orderId: string): AxiosPromise<void> {
		const url = this._urlFabric.retrySap(orderId);

		return this.post(url, {});
	}
}
