import { injectable } from 'inversify';
import { action, computed, observable } from 'mobx';
import { EUserRole } from '../../dto/enum/EUserRole';
import { EnumHelper } from '../../utils/helpers/EnumHelper';
import { isValueDefined } from './../../utils/Utils';

@injectable()
export class RoleService {
	private readonly allRolesNumbers = EnumHelper.getValuesOfEnum(EUserRole);
	public readonly allRolesMask = this.reduceMask(this.allRolesNumbers);
	@observable private _currentRole: EUserRole | undefined = this._getStorageUserRole();
	private readonly USER_ROLE_STORAGE_KEY = 'user_role';

	@computed
	get currentRole(): EUserRole | undefined {
		return this._currentRole;
	}

	@action
	public init(role: EUserRole): void {
		this._currentRole = role;
		this._setStorageUserRole(role);
	}

	@action
	public clearData(): void {
		this._currentRole = undefined;
		this._clearStorageUserRole();
	}

	public hasAcceptableRole(acceptableRoles: EUserRole[], role?: EUserRole): boolean {
		const acceptableRole = role || this._currentRole;

		if (acceptableRoles.length === 0 || !isValueDefined(acceptableRole)) {
			return false;
		}

		const concatenedRoles = this.reduceMask(acceptableRoles);

		return EnumHelper.hasFlag(concatenedRoles, acceptableRole as EUserRole);
	}

	public getAllRolesMaskExcluding(exclRoles: EUserRole[]): EUserRole {
		const excludeMask = this.reduceMask(exclRoles);

		return this.allRolesMask ^ excludeMask;
	}

	private reduceMask(roles: EUserRole[]): EUserRole {
		return roles.reduce((accumulator, currentValue) => accumulator | currentValue);
	}

	protected _setStorageUserRole(role: EUserRole): void {
		localStorage.setItem(this.USER_ROLE_STORAGE_KEY, role.toString());
	}

	protected _clearStorageUserRole(): void {
		localStorage.removeItem(this.USER_ROLE_STORAGE_KEY);
	}

	protected _getStorageUserRole(): EUserRole | undefined {
		const role = localStorage.getItem(this.USER_ROLE_STORAGE_KEY);

		return role ? Number(role) : undefined;
	}
}
