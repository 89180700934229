import { EMPTY_GUID } from '../../shared/constants/Guid';

export const nameof = <T>(name: keyof T): keyof T => name;

export const nestedNameOf = <T, P>(name: keyof T, nestedName: keyof P): string => {
	return `${name}.${nestedName}`;
};

export const isValueDefined = (value: any): boolean => {
	return value !== null && value !== undefined;
};

export const isNumber = (n: number | null | undefined): boolean => {
	return typeof n == 'number' && !isNaN(n) && isFinite(n);
};

export const isValidNumber = (n: unknown): n is number => {
	return isValueDefined(n) && isNumber(n as number);
};

export const isGuidDefined = (guid: string | null | undefined): boolean => {
	return isValidString(guid) && guid !== EMPTY_GUID;
};

export const isValidString = (str: string | null | undefined): boolean => {
	return typeof str === 'string' && !!str;
};

export const createDownloadLink = (blob: Blob, fileName: string): HTMLAnchorElement => {
	const data = URL.createObjectURL(new Blob([blob]));
	const link = document.createElement('a');
	link.href = data;
	link.download = fileName;

	return link;
};

export const hasObjectEmptyProperties = (obj: Record<string, any>): boolean =>
	Object.values(obj).some((x) => !isValueDefined(x) || x === '');

export const isEmptyObject = (obj: Record<string, any>): boolean => {
	return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const getStringUrlSearchParamsByRecord = (obj: Record<string, string>): string =>
	Object.keys(obj)
		.map((key) => `${key}=${obj[key]}`)
		.join('&');

export const getRecordByStringUrlSearchParams = (search: string): Record<string, string> => {
	const record: Record<string, string> = {};

	new URLSearchParams(search).forEach((val, key) => (record[key] = val));

	return record;
};
