import { addMilliseconds } from 'date-fns';
import { injectable } from 'inversify';
import { AuthenticationResponseDto } from './../../models/Auth/AuthenticationResponseDto';

@injectable()
export class AuthService {
	private readonly ACCESS_TOKEN = 'access_token';
	private readonly REFRESH_TOKEN = 'refresh_token';
	private readonly EXPIRES_IN_TIME = 'expires_in';
	private readonly LAST_AUTHORISED_PATH = 'last_authorised_path';

	private readonly STORAGE_TYPE_EVENT = 'storage';

	private readonly cookieClientTimeZoneKey = 'VolmaTrading.ClientTimeZone';

	constructor() {
		window.addEventListener(this.STORAGE_TYPE_EVENT, (e) => this._switchOtherTabStorageEvent(e), false);
	}

	public getExpiresIn(): number {
		const expiresIn = localStorage.getItem(this.EXPIRES_IN_TIME);

		return expiresIn ? +expiresIn : 0;
	}

	public isAccessTokenExpired = (): boolean => {
		return !this.getAccessToken() || Date.now() > this.getExpiresIn();
	};

	public setExpiresInTime(expiresIn: number): void {
		const expirationTime = addMilliseconds(Date.now(), expiresIn * 1000 * 0.8).getTime();

		localStorage.setItem(this.EXPIRES_IN_TIME, expirationTime.toString());
	}

	public getAccessToken(): string | null {
		return localStorage.getItem(this.ACCESS_TOKEN);
	}

	public getRefreshToken(): string | null {
		return localStorage.getItem(this.REFRESH_TOKEN);
	}

	private _setAccessToken(token: string): void {
		localStorage.setItem(this.ACCESS_TOKEN, token);
	}

	private _setRefreshToken(token: string): void {
		localStorage.setItem(this.REFRESH_TOKEN, token);
	}

	public clearRefreshToken(): void {
		localStorage.setItem(this.REFRESH_TOKEN, '');
	}

	public setUserData(authDto: AuthenticationResponseDto): void {
		this._setAccessToken(authDto.access_token);
		this._setRefreshToken(authDto.refresh_token);
		this.setExpiresInTime(authDto.expires_in);

		this._setTimeZoneCookie();
	}

	public clearUserData(): void {
		localStorage.removeItem(this.ACCESS_TOKEN);
		localStorage.removeItem(this.REFRESH_TOKEN);
		localStorage.removeItem(this.EXPIRES_IN_TIME);
	}

	public setLastAuthorisedPath(path: string): void {
		localStorage.setItem(this.LAST_AUTHORISED_PATH, path);
	}

	public getLastAuthorisedPath(): string | null {
		return localStorage.getItem(this.LAST_AUTHORISED_PATH);
	}

	public clearLastAuthorisedPath(): void {
		localStorage.setItem(this.LAST_AUTHORISED_PATH, '');
	}

	private _switchOtherTabStorageEvent(event: StorageEvent): void {
		if (event.storageArea === localStorage) {
			switch (event.key) {
				case this.ACCESS_TOKEN:
					this._handleAccessTokenChange();
					break;
				default:
					break;
			}
		}
	}

	private _handleAccessTokenChange(): void {
		this.clearLastAuthorisedPath();
		window.location.reload();
	}

	private _setTimeZoneCookie(): void {
		const timeOffset = this._getClientTimeZoneOffset();
		document.cookie = `${this.cookieClientTimeZoneKey}=${timeOffset};path=/;`;
	}

	private _getClientTimeZoneOffset(): number {
		return new Date().getTimezoneOffset() * -1;
	}
}
