import React, { ReactNode } from 'react';
import { ValidationMessage } from '../../dto/Special/Validation/ValidationMessage';
import { translate } from '../translate';

export class ErrorFormatter {
	public static format400Message(data?: any): ReactNode {
		let errors: [] = [];

		if (!data.errors) {
			errors = data.map((item: ValidationMessage) => ({
				fieldName: item.fieldName,
				message: translate(item.message, item.parameter.data as Record<string, any>),
			}));
		}

		return (
			<>
				<div className='notification__message'>{translate('notification.400error')}</div>
				{errors.length > 0 && (
					<div className='notification__errors-container'>{JSON.stringify(errors, null, 2)}</div>
				)}
			</>
		);
	}
}
