import { ESupplierWorkflowState } from '../../../dto/enum/ESupplierWorkflowState';
import { useInject } from '../../../hooks/useInject';
import { Types } from '../../../inversify/types';
import { SupplierAccessRuleService } from '../../accessRule/SupplierAccessRuleService';
import { BaseApprover } from '../BaseApprover';

export class SupplierApprover extends BaseApprover {
	private readonly _supplierAccessRuleService = useInject<SupplierAccessRuleService>(Types.SupplierAccessRuleService);

	public canBlockSupplier = (state: ESupplierWorkflowState | undefined): boolean => {
		const hasAcceptableStatus = this.hasAcceptableStatus(state, [
			ESupplierWorkflowState.Admitted,
			ESupplierWorkflowState.Limited,
			ESupplierWorkflowState.OnReview,
		]);
		const hasAcceptableRole = this._supplierAccessRuleService.canBlockSupplier();

		return hasAcceptableRole && hasAcceptableStatus;
	};

	public canRestrictSupplier = (state: ESupplierWorkflowState | undefined): boolean => {
		const hasAcceptableStatus = this.hasAcceptableStatus(state, [
			ESupplierWorkflowState.Admitted,
			ESupplierWorkflowState.OnReview,
		]);
		const hasAcceptableRole = this._supplierAccessRuleService.canRestrictSupplier();

		return hasAcceptableRole && hasAcceptableStatus;
	};

	public canUnblockSupplier = (state: ESupplierWorkflowState | undefined): boolean => {
		const hasAcceptableStatus = this.hasAcceptableStatus(state, [ESupplierWorkflowState.Blocked]);
		const hasAcceptableRole = this._supplierAccessRuleService.canUnblockSupplier();

		return hasAcceptableRole && hasAcceptableStatus;
	};

	public canViewBlockedComment = (state: ESupplierWorkflowState | undefined): boolean => {
		const hasAcceptableStatus = this.hasAcceptableStatus(state, [ESupplierWorkflowState.Blocked]);

		return hasAcceptableStatus;
	};
}
