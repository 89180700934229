import { LimitType } from '../../../components/pages/dictionaries/limits/LimitType';
import { BaseApprover } from '../BaseApprover';

export class LimitApprover extends BaseApprover {
	public canEditByDate = (dto: LimitType): boolean => {
		const date = new Date();
		const currentYear = date.getFullYear();
		const currentMonth = date.getMonth() + 1;

		return dto.year < currentYear || (dto.year === currentYear && dto.month <= currentMonth) ? false : true;
	};
}
