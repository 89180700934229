import { AxiosPromise } from 'axios';
import { FinancialPositionLimitEditorDto } from '../dto/FinancialPositionLimit/FinancialPositionLimitEditorDto';
import { DictionaryDto } from '../dto/Special/DictionaryDto';
import { FinancialPositionLimitRequestDto } from '../models/FinancialPositionLimit/FinancialPositionLimitRequestDto';
import { TableApi } from './TableApi';
export class FinancialPositionApi extends TableApi {
	protected tableUrl: string = this._urlFabric.financialPositionTable;

	public getFinancialPositionLimits(
		financialPositionIds: string[],
		dto: FinancialPositionLimitRequestDto,
	): AxiosPromise<DictionaryDto<FinancialPositionLimitEditorDto>> {
		const uniqueIds = Array.from(new Set(financialPositionIds));
		const url = this._urlFabric.getFinancialPositionLimits(uniqueIds, dto);

		return this.get(url);
	}
}
