import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';
import './Loader.scss';
import LoadingGIF from './loading.gif';

interface ILoaderProps {
	isLoading: boolean;
}

const CLOSE_LOADER_TIMEOUT_MS = 200;

export const Loader: FunctionComponent<ILoaderProps> = (props) => {
	const [isVisible, setVisible] = useState<boolean>(false);

	const onIsLoadingPropChange = (): void | (() => void) => {
		let loaderTimerId: NodeJS.Timeout;

		if (!props.isLoading) {
			loaderTimerId = setTimeout(setCloseOnTimeout, CLOSE_LOADER_TIMEOUT_MS);
		} else {
			setVisible(true);
		}

		return () => {
			clearTimeout(loaderTimerId);
		};
	};

	useEffect(onIsLoadingPropChange, [props.isLoading]);

	const setCloseOnTimeout = (): void => {
		setVisible(false);
	};

	const animationClass = isVisible ? 'open' : 'close';

	return (
		<div className={classNames('loader-container', animationClass)}>
			<div className='loader-wrapper'>
				<img src={LoadingGIF} alt='loading-logo' />
			</div>
		</div>
	);
};
