import { EUserRole } from '../../../dto/enum/EUserRole';
import { UserDto } from '../../../dto/User/UserDto';
import { useInject } from '../../../hooks/useInject';
import { Types } from '../../../inversify/types';
import { MaterialAccessRuleService } from '../../accessRule/MaterialAccessRuleService';
import { RoleService } from '../../role/RoleService';
import { BaseApprover } from '../BaseApprover';

export class MaterialApprover extends BaseApprover {
	private readonly _materialAccessRuleService = useInject<MaterialAccessRuleService>(Types.MaterialAccessRuleService);
	protected readonly roleService = useInject<RoleService>(Types.RoleService);

	public canEdit = (user: UserDto | undefined, isModified: boolean): boolean => {
		if (!this._materialAccessRuleService.canEdit()) {
			return false;
		}

		return !isModified || this.isAdmin(user);
	};

	public showEditConfirm = (user: UserDto | undefined): boolean => {
		return !this.isAdmin(user);
	};

	protected isAdmin = (user: UserDto | undefined): boolean => {
		return this.roleService.hasAcceptableRole([EUserRole.VolmaAdministrator], user?.roles);
	};
}
