import { BidEmergencyTableItemDto } from '../../../dto/BidEmergency/BidEmergencyTableItemDto';
import { EBidEmergencyWorkflowState } from '../../../dto/enum/EBidEmergencyWorkflowState';
import { UserDto } from '../../../dto/User/UserDto';
import { useInject } from '../../../hooks/useInject';
import { Types } from '../../../inversify/types';
import { BidEmergencyEditorExtendedDto } from '../../../models/BidEmergency/BidEmergencyEditorExtendedDto';
import { StringComparer } from '../../../utils/comparers/StringComparer';
import { BidEmergencyAccessRuleService } from '../../accessRule/BidEmergencyAccessRuleService';
import { BaseApprover } from '../BaseApprover';
import { BidEmergencyEditorDto } from './../../../dto/BidEmergency/BidEmergencyEditorDto';

export class BidEmergencyApprover extends BaseApprover {
	private readonly _bidEmergencyAccessRuleService = useInject<BidEmergencyAccessRuleService>(
		Types.BidEmergencyAccessRuleService,
	);

	public canCancel = (user: UserDto | undefined, record: BidEmergencyEditorDto | undefined): boolean => {
		const isCreator = StringComparer.compare(record?.createdById, user?.id);
		const acceptableStatuses = [
			EBidEmergencyWorkflowState.Draft,
			EBidEmergencyWorkflowState.New,
			EBidEmergencyWorkflowState.Revision,
			EBidEmergencyWorkflowState.ApprovedByEngineer,
		];
		const hasAcceptableStatus = this.hasAcceptableStatus(record?.state, acceptableStatuses);

		return isCreator && hasAcceptableStatus;
	};

	public canSendToApprove = (user: UserDto | undefined, item: BidEmergencyEditorExtendedDto | undefined): boolean => {
		const hasAcceptableStatus = this.hasAcceptableStatus(item?.state, [EBidEmergencyWorkflowState.Draft]);
		const isCreator = StringComparer.compare(item?.createdById, user?.id);

		return isCreator && hasAcceptableStatus;
	};

	public canAgreeByLeadEngineer = (state: EBidEmergencyWorkflowState | undefined): boolean => {
		const hasAcceptableStatus = this.hasAcceptableStatus(state, [EBidEmergencyWorkflowState.New]);
		const hasAcceptableRole = this._bidEmergencyAccessRuleService.canAgreeByEngineer();

		return hasAcceptableRole && hasAcceptableStatus;
	};

	public canAgreeByProductionDirector = (state: EBidEmergencyWorkflowState | undefined): boolean => {
		const hasAcceptableStatus = this.hasAcceptableStatus(state, [EBidEmergencyWorkflowState.ApprovedByEngineer]);
		const hasAcceptableRole = this._bidEmergencyAccessRuleService.canAgreeByDirector();

		return hasAcceptableRole && hasAcceptableStatus;
	};

	public canSendToRevision = (state: EBidEmergencyWorkflowState | undefined): boolean => {
		const hasAcceptableRole = this._bidEmergencyAccessRuleService.canRevise(state);

		return hasAcceptableRole;
	};

	public canEdit = (user: UserDto | undefined, bidEmergency: BidEmergencyTableItemDto): boolean => {
		const acceptableStatuses = [EBidEmergencyWorkflowState.Draft, EBidEmergencyWorkflowState.Revision];

		const hasAcceptableStatus = this.hasAcceptableStatus(bidEmergency.state, acceptableStatuses);
		const isCreator = StringComparer.compare(bidEmergency?.createdById, user?.id);

		return isCreator && hasAcceptableStatus;
	};

	public canCreateBiddingClaim = (state: EBidEmergencyWorkflowState | undefined): boolean => {
		const acceptableStatuses = [EBidEmergencyWorkflowState.ApprovedByDirector];

		const hasAcceptableStatus = this.hasAcceptableStatus(state, acceptableStatuses);
		const hasAcceptableRole = this._bidEmergencyAccessRuleService.canCreateBiddingClaim();

		return hasAcceptableRole && hasAcceptableStatus;
	};
}
