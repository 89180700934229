export const Types = {
	UrlFabric: Symbol.for('UrlFabric'),
	AdminApi: Symbol.for('AdminApi'),
	BiddingClaimApi: Symbol.for('BiddingClaimApi'),
	FactoryApi: Symbol.for('FactoryApi'),
	OrderApi: Symbol.for('OrderApi'),
	SupplierApi: Symbol.for('SupplierApi'),
	MaterialApi: Symbol.for('MaterialApi'),
	MaterialGroupApi: Symbol.for('MaterialGroupApi'),
	MaterialTypeApi: Symbol.for('MaterialTypeApi'),
	FinancialPositionApi: Symbol.for('FinancialPositionApi'),
	FinancialManagementUnitApi: Symbol.for('FinancialManagementUnitApi'),
	UserApi: Symbol.for('UserApi'),
	FactoryLimitApi: Symbol.for('FactoryLimitApi'),
	FinancialPositionLimitApi: Symbol.for('FinancialPositionLimitApi'),
	FinancialManagementUnitLimitApi: Symbol.for('FinancialManagementUnitLimitApi'),
	AuthUserApi: Symbol.for('AuthUserApi'),
	FileApi: Symbol.for('FileApi'),
	NeedApi: Symbol.for('NeedApi'),
	SettingsApi: Symbol.for('SettingsApi'),
	BidOfferApi: Symbol.for('BidOfferApi'),
	BidEmergencyApi: Symbol.for('BidEmergencyApi'),
	PurchaseOrganizationApi: Symbol.for('PurchaseOrganizationApi'),
	LocaleApi: Symbol.for('LocaleApi'),
	UnloadTypeApi: Symbol.for('UnloadTypeApi'),
	TransportKindApi: Symbol.for('TransportKindApi'),
	ReportApi: Symbol.for('ReportApi'),
	AuthService: Symbol.for('AuthService'),
	TranslationService: Symbol.for('TranslationService'),
	NotificationService: Symbol.for('NotificationService'),
	MaterialApprover: Symbol.for('MaterialApprover'),
	OrderApprover: Symbol.for('OrderApprover'),
	NeedApprover: Symbol.for('NeedApprover'),
	BiddingClaimApprover: Symbol.for('BiddingClaimApprover'),
	BidOfferApprover: Symbol.for('BidOfferApprover'),
	BidEmergencyApprover: Symbol.for('BidEmergencyApprover'),
	SupplierApprover: Symbol.for('SupplierApprover'),
	UserApprover: Symbol.for('UserApprover'),
	FactoryLimitApprover: Symbol.for('FactoryLimitApprover'),
	FinancialManagementUnitLimitApprover: Symbol.for('FinancialManagementUnitLimitApprover'),
	FinancialPositionLimitApprover: Symbol.for('FinancialPositionLimitApprover'),
	MoveLimitApprover: Symbol.for('MoveLimitApprover'),
	SupplierAccessRuleService: Symbol.for('SupplierAccessRuleService'),
	UserAccessRuleService: Symbol.for('UserAccessRuleService'),
	FactoryAccessRuleService: Symbol.for('FactoryAccessRuleService'),
	FactoryLimitAccessRuleService: Symbol.for('FactoryLimitAccessRuleService'),
	FinancialManagementUnitLimitAccessRuleService: Symbol.for('FinancialManagementUnitLimitAccessRuleService'),
	FinancialManagementUnitAccessRuleService: Symbol.for('FinancialManagementUnitAccessRuleService'),
	FinancialPositionLimitAccessRuleService: Symbol.for('FinancialPositionLimitAccessRuleService'),
	MoveLimitsAccessRuleService: Symbol.for('MoveLimitsAccessRuleService'),
	FinancialPositionAccessRuleService: Symbol.for('FinancialPositionAccessRuleService'),
	MaterialAccessRuleService: Symbol.for('MaterialAccessRuleService'),
	MaterialTypeAccessRuleService: Symbol.for('MaterialTypeAccessRuleService'),
	MaterialGroupAccessRuleService: Symbol.for('MaterialGroupAccessRuleService'),
	NeedAccessRuleService: Symbol.for('NeedAccessRuleService'),
	BidEmergencyAccessRuleService: Symbol.for('BidEmergencyAccessRuleService'),
	BiddingClaimAccessRuleService: Symbol.for('BiddingClaimAccessRuleService'),
	AnalysisBiddingClaimAccessRuleService: Symbol.for('AnalysisBiddingClaimAccessRuleService'),
	BidOfferAccessRuleService: Symbol.for('BidOfferAccessRuleService'),
	OrderAccessRuleService: Symbol.for('OrderAccessRuleService'),
	SettingsAccessRuleService: Symbol.for('SettingsAccessRuleService'),
	LocalizationAccessRuleService: Symbol.for('LocalizationAccessRuleService'),
	UnloadTypeAccessRuleService: Symbol.for('UnloadTypeAccessRuleService'),
	PurchaseOrganizationAccessRuleService: Symbol.for('PurchaseOrganizationAccessRuleService'),
	ReportAccessRuleService: Symbol.for('ReportAccessRuleService'),
	FileService: Symbol.for('FileService'),
	RoleService: Symbol.for('RoleService'),
	ValidationService: Symbol.for('ValidationService'),
	NeedWorkflowStateOrderer: Symbol.for('NeedWorkflowStateOrderer'),
	OrderWorkflowStateOrderer: Symbol.for('OrderWorkflowStateOrderer'),
	BiddingClaimWorkflowStateOrderer: Symbol.for('BiddingClaimWorkflowStateOrderer'),
	BidEmergencyWorkflowStateOrderer: Symbol.for('BidEmergencyWorkflowStateOrderer'),
};
