import { AxiosPromise } from 'axios';
import { TableApi } from './TableApi';

export class LocaleApi extends TableApi {
	protected tableUrl: string = this._urlFabric.localesTable;

	public importLocalization(fileId: string, localizationId: string): AxiosPromise<Blob> {
		const url = this._urlFabric.importLocale(fileId, localizationId);
		return this.post(url, {});
	}

	public exportLocalization(localizationId: string): AxiosPromise<Blob> {
		const url = this._urlFabric.exportLocale(localizationId);
		return this.get(url);
	}
}
