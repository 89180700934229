import { AxiosPromise } from 'axios';
import { MoveLimitDto } from '../dto/FinancialPositionLimit/MoveLimitDto';
import { LimitApi } from './LimitApi';

export class FinancialPositionLimitApi extends LimitApi {
	protected tableUrl: string = this._urlFabric.financialPositionLimitsTable;

	public moveLimit(dto: MoveLimitDto): AxiosPromise<string> {
		const url = this._urlFabric.moveLimit(this.tableUrl);

		return this.post(url, dto);
	}
}
