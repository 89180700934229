import { injectable } from 'inversify';
import { FinancialPositionLimitRequestDto } from '../../app/models/FinancialPositionLimit/FinancialPositionLimitRequestDto';
import { EReportType } from './../../app/dto/enum/EReportType';
import { FactoryLimitRequestDto } from './../../app/models/FactoryLimit/FactoryLimitRequestDto';

@injectable()
export class UrlFabric {
	private readonly _apiUrl = '/api';
	private readonly _authUrl = '/api';

	//#region Auth
	public logIn(): string {
		return this._authUrl + `/connect/token`;
	}

	public refreshToken(): string {
		return this._authUrl + `/connect/token`;
	}
	//#endregion

	//#region BiddingClaim
	public readonly biddingClaimsTable: string = this._apiUrl + '/biddingclaims';

	public setBidOfferConsumptions(biddingClaimId: string): string {
		return this.biddingClaimsTable + `/${biddingClaimId}/biddingoffers/consumptions`;
	}

	public getBiddingClaimOrders(biddingClaimId: string): string {
		return this.biddingClaimsTable + `/${biddingClaimId}/orders`;
	}

	public getBiddingClaimSchedule(biddingClaimId: string): string {
		return this.biddingClaimsTable + `/${biddingClaimId}/schedule`;
	}

	public approveSuppliers(biddingClaimId: string): string {
		return this.biddingClaimsTable + `/${biddingClaimId}/suppliers/approve`;
	}

	public getSuppliers(biddingClaimId: string): string {
		return this.biddingClaimsTable + `/${biddingClaimId}/suppliers?filter_Approved=true`;
	}

	public stopBiddingClaim(biddingClaimId: string): string {
		return this.biddingClaimsTable + `/${biddingClaimId}/stop`;
	}

	public initiationCreate(): string {
		return this.biddingClaimsTable + `/initiationcreate`;
	}

	//#endregion

	//#region Factory
	public readonly factoryTable: string = this._apiUrl + '/factories';

	public getFactoryLimits(factoryId: string, dto: FactoryLimitRequestDto): string {
		return this.factoryTable + `/${factoryId}/limits?year=${dto.year}&month=${dto.month}`;
	}

	//#endregion

	//#region Order
	public readonly orderTable: string = this._apiUrl + '/orders';

	public sendOrderToAgreement(orderId: string): string {
		return this.orderTable + `/${orderId}/sendtoagreement`;
	}

	public agreeOrder(orderId: string): string {
		return this.orderTable + `/${orderId}/agree`;
	}

	public disagreeOrder(orderId: string): string {
		return this.orderTable + `/${orderId}/disagree`;
	}

	public cancelOrder(orderId: string): string {
		return this.orderTable + `/${orderId}/cancel`;
	}

	public retrySap(orderId: string): string {
		return this.orderTable + `/${orderId}/retrysap`;
	}
	//#endregion

	//#region Bid Offer
	public readonly bidOffersTable: string = this._apiUrl + '/biddingoffers';

	public bidOffersCreateUpdateUrl(biddingClaimId: string): string {
		return this.biddingClaimsTable + `/${biddingClaimId}/biddingoffers`;
	}

	public getBidOffersByBiddingClaim(biddingClaimId: string, materialId: string): string {
		return this.biddingClaimsTable + `/${biddingClaimId}/materials/${materialId}/biddingoffers`;
	}

	public getSupplierMaterialOffers(biddingClaimId: string, supplierId: string): string {
		return this.biddingClaimsTable + `/${biddingClaimId}/suppliers/${supplierId}/biddingoffers`;
	}
	//#endregion

	//#region Bid Emergency
	public readonly bidEmergencyTable: string = this._apiUrl + '/bidemergencies';

	public sendBidEmergencyToRevision(bidEmergencyId: string): string {
		return this.bidEmergencyTable + `/${bidEmergencyId}/sendtoreview`;
	}

	public cancelBidEmergency(bidEmergencyId: string): string {
		return this.bidEmergencyTable + `/${bidEmergencyId}/cancel`;
	}

	public agreeBidEmergencyByLeadEngineer(bidEmergencyId: string): string {
		return this.bidEmergencyTable + `/${bidEmergencyId}/agreebyleadengineer`;
	}

	public agreeBidEmergencyByProductionDirector(bidEmergencyId: string): string {
		return this.bidEmergencyTable + `/${bidEmergencyId}/agreebydirection`;
	}

	public sendBidEmergencyToApprove(bidEmergencyId: string): string {
		return this.bidEmergencyTable + `/${bidEmergencyId}/sendtoapprove`;
	}

	//#endregion

	//#region Material
	public readonly materialTable: string = this._apiUrl + '/materials';

	public getSAPMaterialPrice(materialId: string, factoryId: string): string {
		return `${this.materialTable}/${materialId}/factories/${factoryId}/price`;
	}

	public getSAPMaterialBalance(materialId: string, factoryId: string): string {
		return `${this.materialTable}/${materialId}/factories/${factoryId}/balance`;
	}

	//#endregion

	//#region Material group
	public readonly materialGroupTable: string = this._apiUrl + '/materialgroups';
	//#endregion

	//#region Unload type
	public readonly unloadTypeTable: string = this._apiUrl + '/unloadtypes';
	//#endregion

	//#region Material type
	public readonly materialTypeTable: string = this._apiUrl + '/materialtypes';
	//#endregion

	//#region Purchase organization
	public readonly purchaseOrganizationTable: string = this._apiUrl + '/purchasers';
	//#endregion

	//#region Need
	public readonly needTable: string = this._apiUrl + '/needs';

	public fillNeed(needId: string): string {
		return this.needTable + `/${needId}/fillneed`;
	}

	public getRefusedNeeds(year: number, month: number): string {
		return this.needTable + `/refused?year=${year}&month=${month}`;
	}

	public sendNeedToReview(needId: string): string {
		return this.needTable + `/${needId}/sendtoreview`;
	}

	public cancelNeed(needId: string): string {
		return this.needTable + `/${needId}/cancel`;
	}

	public agreeNeed(needId: string): string {
		return this.needTable + `/${needId}/agree`;
	}

	public batchAgreeNeeds(): string {
		return this.needTable + `/batch/agree`;
	}

	public batchSendToReviewNeeds(): string {
		return this.needTable + `/batch/sendtoreview`;
	}

	public batchCopyNeeds(): string {
		return this.needTable + `/batch/copy`;
	}
	//#endregion

	//#region Transport kind
	public readonly transportKindTable: string = this._apiUrl + '/transportkinds';
	//#endregion

	//#region Financial position
	public readonly financialPositionTable: string = this._apiUrl + '/financialpositions';

	public getFinancialPositionLimits(financialPositionIds: string[], dto: FinancialPositionLimitRequestDto): string {
		const concatenedFinancialPositions = financialPositionIds.map((id) => `&financialPositionsId=${id}`).join('');

		return (
			this.financialPositionTable +
			`/limits?year=${dto.year}&month=${dto.month}&financialUnitId=${dto.financialUnitId}${concatenedFinancialPositions}`
		);
	}
	//#endregion

	//#region Financial management units
	public readonly financialManagementUnitsTable: string = this._apiUrl + '/financialmanagementunits';

	public getFinancialManagementUnitsLimits(financialUnitId: string, year: number, month: number): string {
		return this.financialManagementUnitsTable + `/${financialUnitId}/limits?year=${year}&month=${month}`;
	}
	//#endregion

	//#region Users
	public readonly userTable: string = this._apiUrl + '/users';

	public getCurrentUser(): string {
		return this.userTable + `/current`;
	}
	//#endregion

	//#region Reports
	public readonly reportTable: string = this._apiUrl + '/reports';

	public getReportParameters(type: EReportType): string {
		return `${this.reportTable}/${type}/parameters`;
	}

	public getReportUrl(type: EReportType, parameterId: string): string {
		return `${this.reportTable}/${type}/${parameterId}`;
	}
	//#endregion

	//#region Limits
	public getLimitStatistic(tableUrl: string): string {
		return `${tableUrl}/statistic`;
	}

	public moveLimit(tableUrl: string): string {
		return `${tableUrl}/move`;
	}
	//#endregion

	//#region FactoryLimits
	public readonly factoryLimitsTable: string = this._apiUrl + '/factorylimits';
	//#endregion

	//#region FinancialPosition limits
	public readonly financialPositionLimitsTable: string = this._apiUrl + '/financialpositionlimits';
	//#endregion

	//#region Financial management unit limits
	public readonly financialManagementUnitLimitsTable: string = this._apiUrl + '/financialmanagementunitlimits';
	//#endregion

	//#region Supplier
	public readonly supplierTable: string = this._apiUrl + '/suppliers';

	public registerSupplier(): string {
		return `${this.supplierTable}/register`;
	}

	public setSapSupplierCode(supplierId: string): string {
		return `${this.supplierTable}/${supplierId}/sapcode`;
	}

	public setSupplierMetrics(supplierId: string): string {
		return `${this.supplierTable}/${supplierId}/metrics`;
	}

	public getSupplierComplaints(supplierId: string): string {
		return `${this.supplierTable}/${supplierId}/complaints`;
	}

	public blockSupplier(supplierId: string): string {
		return `${this.supplierTable}/${supplierId}/block`;
	}

	public unblockSupplier(supplierId: string): string {
		return `${this.supplierTable}/${supplierId}/unblock`;
	}

	public restrictSupplier(supplierId: string): string {
		return `${this.supplierTable}/${supplierId}/restrict`;
	}
	//#endregion

	//#region Complaints
	public readonly complaintsTable: string = this._apiUrl + '/complaints';

	public createComplaint(): string {
		return this.complaintsTable;
	}
	//#endregion

	//#region File
	public readonly filesTable: string = this._apiUrl + '/files';

	public createFile(): string {
		return this.filesTable;
	}

	public storeFileChunk(id: string): string {
		return this.filesTable + `/${id}/store`;
	}

	public finishFile(id: string): string {
		return this.filesTable + `/${id}/finish`;
	}

	public loadFile(id: string): string {
		return this.filesTable + `/${id}`;
	}
	//#endregion

	//#region Locales
	public readonly localesTable: string = this._apiUrl + '/locales';

	public exportLocale(id: string): string {
		return `${this.localesTable}/export/${id}`;
	}

	public importLocale(blobId: string, localeId: string): string {
		return `${this.localesTable}/import/${localeId}?blobId=${blobId}`;
	}
	//#endregion

	//#region Admin
	public rebuildReadModel(): string {
		return this._apiUrl + `/Administrator/readmodel/rebuild`;
	}
	//#endregion

	//#region Settings
	public getBiddingSettings(): string {
		return this._apiUrl + `/settings/biddingSettings`;
	}
	//#endregion
}
