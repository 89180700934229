import { AxiosPromise } from 'axios';
import { DisagreeBidEmergencyDto } from './../dto/BidEmergency/DisagreeBidEmergencyDto';
import { TableApi } from './TableApi';

export class BidEmergencyApi extends TableApi {
	protected tableUrl: string = this._urlFabric.bidEmergencyTable;

	public sendBidEmergencyToRevision(dto: DisagreeBidEmergencyDto): AxiosPromise<void> {
		const url = this._urlFabric.sendBidEmergencyToRevision(dto.id);

		return this.post(url, dto);
	}

	public cancelBidEmergency(bidEmergencyId: string): AxiosPromise<void> {
		const url = this._urlFabric.cancelBidEmergency(bidEmergencyId);

		return this.post(url, {});
	}

	public agreeBidEmergencyByLeadEngineer(bidEmergencyId: string): AxiosPromise<void> {
		const url = this._urlFabric.agreeBidEmergencyByLeadEngineer(bidEmergencyId);

		return this.post(url, {});
	}

	public agreeBidEmergencyByProductionDirector(bidEmergencyId: string): AxiosPromise<void> {
		const url = this._urlFabric.agreeBidEmergencyByProductionDirector(bidEmergencyId);

		return this.post(url, {});
	}

	public sendBidEmergencyToApprove(bidEmergencyId: string): AxiosPromise<void> {
		const url = this._urlFabric.sendBidEmergencyToApprove(bidEmergencyId);

		return this.post(url, {});
	}
}
