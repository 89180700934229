import { EMonth } from '../../dto/enum/EMonth';
import { EnumHelper } from '../helpers/EnumHelper';
import { PropertyHelper } from '../helpers/PropertyHelper';
import { translate } from '../translate';
import { DateTimeFormatter } from './DateTimeFormatter';
import { NumberFormatter } from './NumberFormatter';

export class ValidationMessageFormatter {
	public static getStringMessage = (text: string, value: number): string =>
		`${translate(text)} ${value} ${translate('validation.string.characters')}`;

	public static getNumberMessage = (text: string, value: number): string =>
		`${translate(text)} ${NumberFormatter.getLocalizedNumberString(value)}`;

	public static getArrayMessage = (text: string, value: number): string => `${translate(text)} ${value}`;

	public static getDateMessage = (text: string, value: Date | string): string =>
		`${translate(text)} ${DateTimeFormatter.getDateTimeWithSecondsString(new Date(value))}`;

	public static getMonthMessage = (text: string, value: number): string =>
		`${translate(text)} - ${EnumHelper.translateEnumValue(
			PropertyHelper.getFirstKeyName({ EMonth }),
			EMonth[value],
		)}`;

	public static getYearMessage = (text: string, value: number): string => `${translate(text)} - ${value}`;
}
