import { NeedTableItemDto } from '../../../dto/Need/NeedTableItemDto';
import { UserDto } from '../../../dto/User/UserDto';
import { useInject } from '../../../hooks/useInject';
import { Types } from '../../../inversify/types';
import { StringComparer } from '../../../utils/comparers/StringComparer';
import { NeedAccessRuleService } from '../../accessRule/NeedAccessRuleService';
import { BaseApprover } from '../BaseApprover';
import { ENeedWorkflowState } from './../../../dto/enum/ENeedWorkflowState';
import { NeedEditorDto } from './../../../dto/Need/NeedEditorDto';

export class NeedApprover extends BaseApprover {
	private readonly _needAccessRuleService = useInject<NeedAccessRuleService>(Types.NeedAccessRuleService);
	public readonly getBatchCopyNeedStatuses = (): ENeedWorkflowState[] => [
		ENeedWorkflowState.New,
		ENeedWorkflowState.TermsAndPricesIndicated,
		ENeedWorkflowState.ApprovedByEngineer,
		ENeedWorkflowState.ApprovedByDirector,
		ENeedWorkflowState.InProgress,
		ENeedWorkflowState.Finished,
	];

	public canFillNeed = (needState: ENeedWorkflowState | undefined): boolean => {
		const hasAcceptableStatus = this.hasAcceptableStatus(needState, [ENeedWorkflowState.New]);
		const hasAcceptableRole = this._needAccessRuleService.canFillNeed();

		return hasAcceptableRole && hasAcceptableStatus;
	};

	public canCancel = (user: UserDto | undefined, record: NeedEditorDto | undefined): boolean => {
		const isCreator = StringComparer.compare(record?.createdById, user?.id);
		const acceptableStatuses = [
			ENeedWorkflowState.Draft,
			ENeedWorkflowState.New,
			ENeedWorkflowState.Revision,
			ENeedWorkflowState.TermsAndPricesIndicated,
			ENeedWorkflowState.ApprovedByEngineer,
			ENeedWorkflowState.ApprovedByDirector,
		];

		return isCreator && this.hasAcceptableStatus(record?.state, acceptableStatuses);
	};

	public canEdit = (
		user: UserDto | undefined,
		need:
			| {
					state: ENeedWorkflowState | undefined;
					createdById: string | undefined;
			  }
			| undefined,
	): boolean => {
		const acceptableStatuses = [ENeedWorkflowState.Draft, ENeedWorkflowState.Revision];
		const isCreator = StringComparer.compare(need?.createdById, user?.id);

		return isCreator && this.hasAcceptableStatus(need?.state, acceptableStatuses);
	};

	public canSendToReview = (needState: ENeedWorkflowState | undefined): boolean => {
		return this._needAccessRuleService.canSendToReviewByState(needState);
	};

	public canAgreeByLeadEngineer = (needState: ENeedWorkflowState | undefined): boolean => {
		const hasAcceptableRole = this._needAccessRuleService.canAgreeByEngineer();
		const hasAcceptableStatus = this.hasAcceptableStatus(needState, [ENeedWorkflowState.TermsAndPricesIndicated]);

		return hasAcceptableRole && hasAcceptableStatus;
	};

	public canAgreeByProductionDirector = (needState: ENeedWorkflowState | undefined): boolean => {
		const hasAcceptableRole = this._needAccessRuleService.canAgreeByDirector();
		const hasAcceptableStatus = this.hasAcceptableStatus(needState, [ENeedWorkflowState.ApprovedByEngineer]);

		return hasAcceptableRole && hasAcceptableStatus;
	};

	public canBatchAgree = (selectedNeeds: NeedTableItemDto[]): boolean => {
		return selectedNeeds.every(
			(item) => this.canAgreeByProductionDirector(item.state) || this.canAgreeByLeadEngineer(item.state),
		);
	};

	public canBatchSendToReview = (selectedNeeds: NeedTableItemDto[]): boolean => {
		return selectedNeeds.every((item) => this.canSendToReview(item.state));
	};

	public isNeedToBlockNextMonth = (date: Date, addingToBidForNextMonthLimit: number): boolean =>
		date.getDate() > addingToBidForNextMonthLimit;

	public isNeedToLoadMaterialBalanceFromSAP = (needState: ENeedWorkflowState | undefined): boolean => {
		const acceptableStatuses = [ENeedWorkflowState.Draft, ENeedWorkflowState.New, ENeedWorkflowState.Revision];

		return this.hasAcceptableStatus(needState, acceptableStatuses);
	};

	public areLimitsShouldCalculate = (needState: ENeedWorkflowState | undefined): boolean => {
		const acceptableStatuses = [ENeedWorkflowState.Draft, ENeedWorkflowState.Revision];

		return this.hasAcceptableStatus(needState, acceptableStatuses);
	};
}
