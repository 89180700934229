import { translate } from '../translate';
import { ISelectOption } from './../../../shared/types/ISelectOption';
import { isNumber, isValueDefined } from './../Utils';

export class EnumHelper {
	public static getOptionsOfEnum = (
		enumName: string,
		enumObject: Record<string, any>,
		asStringValues = false,
	): ISelectOption[] => {
		return EnumHelper.getKeysOfEnum(enumObject).map((value: string) => ({
			id: value,
			value: asStringValues ? value : enumObject[value],
			label: EnumHelper.translateEnumValue(enumName, value),
		}));
	};

	public static getKeysOfEnum = (enumObject: Record<string, any>): string[] => {
		return Object.keys(enumObject).filter((item) => isNaN(Number(item)));
	};

	public static getValuesOfEnum = (enumObject: Record<string, any>): number[] => {
		return Object.values(enumObject).filter((item) => isNumber(item));
	};

	public static translateEnumValue = (name: string, value: string): string => {
		if (!isValueDefined(value)) {
			return '';
		}

		return translate(`${name}_${value}`);
	};

	public static hasFlag = (value: number, flag: number): boolean => {
		return (value & flag) !== 0;
	};
}
