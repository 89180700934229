import { IAnalysisBiddingClaimEditorPageRouteProps } from '../components/pages/analysisBiddingClaim/analysisBiddingEditorPage/AnalysisBiddingClaimEditorPage';
import { IBidEmergencyPageRouteProps } from '../components/pages/bidEmergency/form/IBidEmergencyPageRouteProps';
import { IBidOfferPageRouteProps } from '../components/pages/bidOfferPage/BidOfferPage';
import { INeedPageRouteProps } from '../components/pages/needs/form/INeedPageRouteProps';
import { IOrderPageRouteProps } from '../components/pages/orders/IOrderPageRouteProps';
import { RouteHelper } from '../utils/helpers/RouteHelper';
import { nameof } from '../utils/Utils';
import { IBiddingClaimEmergencyPageRouteProps } from './../components/pages/biddingClaim/biddingClaimCreatePage/IBiddingClaimEmergencyPageRouteProps';
import { IRouteId } from './../utils/helpers/RouteHelper';

export const ROOT_ROUTE = '/';

export const ACCESS_DENIED_ROUTE = '/accessdenied';

const BIDDING_CLAIM_ROUTE = '/biddingclaims';
export const BIDDING_CLAIM_CREATE_ROUTE = RouteHelper.generateCreateRoute(BIDDING_CLAIM_ROUTE);
export const BIDDING_CLAIM_LIST_ROUTE = RouteHelper.generateListRoute(BIDDING_CLAIM_ROUTE);
export const BIDDING_CLAIM_VIEW_ROUTE = RouteHelper.generateRouteWithId({
	routeUrl: RouteHelper.generateViewRoute(BIDDING_CLAIM_ROUTE),
});
export const BIDDING_CLAIM_EDITOR_ROUTE = RouteHelper.generateRouteWithId({
	routeUrl: RouteHelper.generateEditorRoute(BIDDING_CLAIM_ROUTE),
});

const BIDDING_CLAIM_RETRADE_ROUTE = `${BIDDING_CLAIM_ROUTE}/retrade`;
export const BIDDING_CLAIM_RETRADE_PAGE_ROUTE = RouteHelper.generateRouteWithId({
	routeUrl: BIDDING_CLAIM_RETRADE_ROUTE,
});

const BIDDING_CLAIM_EMERGENCY_ROUTE = `${BIDDING_CLAIM_ROUTE}/emergency`;
const BIDDING_CLAIM_EMERGENCY_IDS_NAMES: IRouteId[] = [
	{ name: nameof<IBiddingClaimEmergencyPageRouteProps>('bidEmergencyId') },
];
export const BIDDING_CLAIM_EMERGENCY_PAGE_ROUTE = RouteHelper.generateRouteWithIdsNames({
	routeUrl: BIDDING_CLAIM_EMERGENCY_ROUTE,
	ids: BIDDING_CLAIM_EMERGENCY_IDS_NAMES,
});

const BID_OFFER_ROUTE = '/bidoffer';

const BID_OFFER_IDS_NAMES: IRouteId[] = [
	{
		name: nameof<IBidOfferPageRouteProps>('biddingClaimId'),
	},
];

export const BID_OFFER_CREATE_ROUTE = RouteHelper.generateRouteWithIdsNames({
	routeUrl: RouteHelper.generateCreateRoute(BID_OFFER_ROUTE),
	ids: BID_OFFER_IDS_NAMES,
});

export const BID_OFFER_EDITOR_ROUTE = RouteHelper.generateRouteWithIdsNames({
	routeUrl: RouteHelper.generateEditorRoute(BID_OFFER_ROUTE),
	ids: BID_OFFER_IDS_NAMES,
});

const BID_EMERGENCY_ROUTE = '/bidemergencies';

const BID_EMERGENCY_IDS_NAMES: IRouteId[] = [
	{
		name: nameof<IBidEmergencyPageRouteProps>('bidEmergencyId'),
	},
];

export const BID_EMERGENCY_LIST_ROUTE = RouteHelper.generateListRoute(BID_EMERGENCY_ROUTE);
export const BID_EMERGENCY_CREATE_ROUTE = RouteHelper.generateCreateRoute(BID_EMERGENCY_ROUTE);
export const BID_EMERGENCY_VIEW_ROUTE = RouteHelper.generateRouteWithId({
	routeUrl: RouteHelper.generateViewRoute(BID_EMERGENCY_ROUTE),
});
export const BID_EMERGENCY_EDITOR_ROUTE = RouteHelper.generateRouteWithIdsNames({
	routeUrl: RouteHelper.generateEditorRoute(BID_EMERGENCY_ROUTE),
	ids: BID_EMERGENCY_IDS_NAMES,
});

const NEED_ROUTE = '/needs';
const NEED_IDS_NAMES: IRouteId[] = [{ name: nameof<INeedPageRouteProps>('needId') }];
const NEED_VOLMA_MATERIAL_SUPPORT_ROUTE = `${NEED_ROUTE}/fillneed`;
const NEED_COPY_ROUTE = `${NEED_ROUTE}/copy`;

export const NEED_CREATE_ROUTE = RouteHelper.generateCreateRoute(NEED_ROUTE);
export const NEED_BATCH_COPY_PAGE_ROUTE = NEED_COPY_ROUTE;
export const NEED_VIEW_ROUTE = RouteHelper.generateRouteWithId({ routeUrl: RouteHelper.generateViewRoute(NEED_ROUTE) });
export const NEED_VOLMA_MATERIAL_SUPPORT_PAGE_ROUTE = RouteHelper.generateRouteWithIdsNames({
	routeUrl: NEED_VOLMA_MATERIAL_SUPPORT_ROUTE,
	ids: NEED_IDS_NAMES,
});
export const NEED_COPY_PAGE_ROUTE = RouteHelper.generateRouteWithIdsNames({
	routeUrl: NEED_COPY_ROUTE,
	ids: NEED_IDS_NAMES,
});

export const NEED_EDITOR_ROUTE = RouteHelper.generateRouteWithId({
	routeUrl: RouteHelper.generateEditorRoute(NEED_ROUTE),
});
export const NEED_LIST_ROUTE = RouteHelper.generateListRoute(NEED_ROUTE);

const MATERIAL_ROUTE = '/materials';
export const MATERIAL_LIST_ROUTE = RouteHelper.generateListRoute(MATERIAL_ROUTE);
export const MATERIAL_VIEW_ROUTE = RouteHelper.generateRouteWithId({
	routeUrl: RouteHelper.generateViewRoute(MATERIAL_ROUTE),
});
export const MATERIAL_EDITOR_ROUTE = RouteHelper.generateRouteWithId({
	routeUrl: RouteHelper.generateEditorRoute(MATERIAL_ROUTE),
});

const PURCHASE_ORGANIZATION_ROUTE = '/purchaseorganizations';
export const PURCHASE_ORGANIZATION_LIST_ROUTE = RouteHelper.generateListRoute(PURCHASE_ORGANIZATION_ROUTE);

const UNLOAD_TYPE_ROUTE = '/unloadtypes';
export const UNLOAD_TYPE_LIST_ROUTE = RouteHelper.generateListRoute(UNLOAD_TYPE_ROUTE);

const MATERIAL_GROUP_ROUTE = '/materialgroups';
export const MATERIAL_GROUP_LIST_ROUTE = RouteHelper.generateListRoute(MATERIAL_GROUP_ROUTE);
export const MATERIAL_GROUP_VIEW_ROUTE = RouteHelper.generateRouteWithId({
	routeUrl: RouteHelper.generateViewRoute(MATERIAL_GROUP_ROUTE),
});
export const MATERIAL_GROUP_EDITOR_ROUTE = RouteHelper.generateRouteWithId({
	routeUrl: RouteHelper.generateEditorRoute(MATERIAL_GROUP_ROUTE),
});

const MATERIAL_TYPE_ROUTE = '/materialtypes';
export const MATERIAL_TYPE_LIST_ROUTE = RouteHelper.generateListRoute(MATERIAL_TYPE_ROUTE);
export const MATERIAL_TYPE_VIEW_ROUTE = RouteHelper.generateRouteWithId({
	routeUrl: RouteHelper.generateViewRoute(MATERIAL_TYPE_ROUTE),
});

const FINANCIAL_POSITION_ROUTE = '/financialpositions';
export const FINANCIAL_POSITION_LIST_ROUTE = RouteHelper.generateListRoute(FINANCIAL_POSITION_ROUTE);
export const FINANCIAL_POSITION_VIEW_ROUTE = RouteHelper.generateRouteWithId({
	routeUrl: RouteHelper.generateViewRoute(FINANCIAL_POSITION_ROUTE),
});

const FINANCIAL_MANAGEMENT_UNIT_ROUTE = '/financialmanagementunits';
export const FINANCIAL_MANAGEMENT_UNIT_LIST_ROUTE = RouteHelper.generateListRoute(FINANCIAL_MANAGEMENT_UNIT_ROUTE);
export const FINANCIAL_MANAGEMENT_UNIT_VIEW_ROUTE = RouteHelper.generateRouteWithId({
	routeUrl: RouteHelper.generateViewRoute(FINANCIAL_MANAGEMENT_UNIT_ROUTE),
});

const USER_ROUTE = '/users';
export const USER_LIST_ROUTE = RouteHelper.generateListRoute(USER_ROUTE);
export const USER_CREATE_ROUTE = RouteHelper.generateCreateRoute(USER_ROUTE);
export const USER_VIEW_ROUTE = RouteHelper.generateRouteWithId({
	routeUrl: RouteHelper.generateViewRoute(USER_ROUTE),
});

const FACTORY_LIMIT_ROUTE = '/factorylimits';
export const FACTORY_LIMIT_CREATE_ROUTE = RouteHelper.generateCreateRoute(FACTORY_LIMIT_ROUTE);
export const FACTORY_LIMIT_LIST_ROUTE = RouteHelper.generateListRoute(FACTORY_LIMIT_ROUTE);
export const FACTORY_LIMIT_EDITOR_ROUTE = RouteHelper.generateRouteWithId({
	routeUrl: RouteHelper.generateEditorRoute(FACTORY_LIMIT_ROUTE),
});
export const FACTORY_LIMIT_VIEW_ROUTE = RouteHelper.generateRouteWithId({
	routeUrl: RouteHelper.generateViewRoute(FACTORY_LIMIT_ROUTE),
});

const FINANCIAL_POSITION_LIMIT_ROUTE = '/financialpositionlimits';
export const FINANCIAL_POSITION_LIMIT_CREATE_ROUTE = RouteHelper.generateCreateRoute(FINANCIAL_POSITION_LIMIT_ROUTE);
export const FINANCIAL_POSITION_LIMIT_LIST_ROUTE = RouteHelper.generateListRoute(FINANCIAL_POSITION_LIMIT_ROUTE);
export const FINANCIAL_POSITION_LIMIT_EDITOR_ROUTE = RouteHelper.generateRouteWithId({
	routeUrl: RouteHelper.generateEditorRoute(FINANCIAL_POSITION_LIMIT_ROUTE),
});
export const FINANCIAL_POSITION_LIMIT_VIEW_ROUTE = RouteHelper.generateRouteWithId({
	routeUrl: RouteHelper.generateViewRoute(FINANCIAL_POSITION_LIMIT_ROUTE),
});

export const LOCALIZATION_ROUTE = '/localizations';
export const LOCALIZATION_LIST_ROUTE = RouteHelper.generateListRoute(LOCALIZATION_ROUTE);
export const LOCALIZATION_EDITOR_ROUTE = RouteHelper.generateRouteWithId({
	routeUrl: RouteHelper.generateEditorRoute(LOCALIZATION_ROUTE),
});

const FINANCIAL_MANAGEMENT_UNIT_LIMIT_ROUTE = '/financialmanagementunitlimits';
export const FINANCIAL_MANAGEMENT_UNIT_LIMIT_CREATE_ROUTE = RouteHelper.generateCreateRoute(
	FINANCIAL_MANAGEMENT_UNIT_LIMIT_ROUTE,
);
export const FINANCIAL_MANAGEMENT_UNIT_LIMIT_LIST_ROUTE = RouteHelper.generateListRoute(
	FINANCIAL_MANAGEMENT_UNIT_LIMIT_ROUTE,
);
export const FINANCIAL_MANAGEMENT_UNIT_LIMIT_EDITOR_ROUTE = RouteHelper.generateRouteWithId({
	routeUrl: RouteHelper.generateEditorRoute(FINANCIAL_MANAGEMENT_UNIT_LIMIT_ROUTE),
});
export const FINANCIAL_MANAGEMENT_UNIT_LIMIT_VIEW_ROUTE = RouteHelper.generateRouteWithId({
	routeUrl: RouteHelper.generateViewRoute(FINANCIAL_MANAGEMENT_UNIT_LIMIT_ROUTE),
});

const FACTORY_ROUTE = '/factories';
export const FACTORY_LIST_ROUTE = RouteHelper.generateListRoute(FACTORY_ROUTE);
export const FACTORY_VIEW_ROUTE = RouteHelper.generateRouteWithId({
	routeUrl: RouteHelper.generateViewRoute(FACTORY_ROUTE),
});
export const FACTORY_EDITOR_ROUTE = RouteHelper.generateRouteWithId({
	routeUrl: RouteHelper.generateEditorRoute(FACTORY_ROUTE),
});

const SUPPLIER_ROUTE = '/suppliers';
export const SUPPLIER_LIST_ROUTE = RouteHelper.generateListRoute(SUPPLIER_ROUTE);
export const SUPPLIER_REGISTRATION_ROUTE = '/register';
export const SUPPLIER_INFO_ROUTE = '/info';
export const SUPPLIER_EDIT_INFO_ROUTE = '/info/edit';

const ANALYSIS_BIDDING_CLAIM_ROUTE = BIDDING_CLAIM_ROUTE + '/analysis';

const ANALYSIS_BIDDING_CLAIM_LIST_VIEW_IDS_NAMES: IRouteId[] = [
	{ name: nameof<IAnalysisBiddingClaimEditorPageRouteProps>('biddingClaimId') },
];

export const ANALYSIS_BIDDING_CLAIM_LIST_VIEW_ROUTE = RouteHelper.generateRouteWithIdsNames({
	routeUrl: ANALYSIS_BIDDING_CLAIM_ROUTE,
	ids: ANALYSIS_BIDDING_CLAIM_LIST_VIEW_IDS_NAMES,
});

const ANALYSIS_BIDDING_CLAIM_EDITOR_IDS_NAMES: IRouteId[] = [
	{ name: nameof<IAnalysisBiddingClaimEditorPageRouteProps>('materialId') },
];

export const ANALYSIS_BIDDING_CLAIM_EDITOR_ROUTE = RouteHelper.generateRouteWithIdsNames({
	routeUrl: RouteHelper.generateEditorRoute(ANALYSIS_BIDDING_CLAIM_LIST_VIEW_ROUTE),
	ids: ANALYSIS_BIDDING_CLAIM_EDITOR_IDS_NAMES,
});

const ORDER_ROUTE = '/orders';
const ORDER_FORM_ROUTE = ANALYSIS_BIDDING_CLAIM_LIST_VIEW_ROUTE + ORDER_ROUTE;
const ORDER_IDS_NAMES: IRouteId[] = [{ name: nameof<IOrderPageRouteProps>('orderId') }];

export const ORDER_FORM_PAGE_ROUTE = ORDER_FORM_ROUTE;
export const ORDER_EDIT_ROUTE = RouteHelper.generateRouteWithIdsNames({
	routeUrl: RouteHelper.generateEditorRoute(ORDER_FORM_ROUTE),
	ids: ORDER_IDS_NAMES,
});
export const ORDER_CREATE_ROUTE = RouteHelper.generateCreateRoute(ORDER_FORM_ROUTE);

export const ORDER_VIEW_ROUTE = RouteHelper.generateRouteWithId({
	routeUrl: RouteHelper.generateViewRoute(ORDER_ROUTE),
});
export const ORDER_LIST_ROUTE = RouteHelper.generateListRoute(ORDER_ROUTE);

export const ADMIN_PAGE = '/admin';
export const AUTH_PAGE = '/auth';
export const LOGOUT_PAGE = '/logout';
export const SETTINGS_PAGE = '/settings';
const REPORT_PAGE = '/reports';
export const BIDDING_CLAIM_JOURNAL_REPORT_PAGE = REPORT_PAGE + '/biddingclaimjournal';
export const BIDDING_CLAIM_OFFERS_REPORT_PAGE = REPORT_PAGE + '/biddingclaimoffers';
export const PAYMENT_OBLIGATION_REPORT_PAGE = REPORT_PAGE + '/paymentobligation';
export const DELIVERIES_REPORT_PAGE = REPORT_PAGE + '/deliveries';
export const NEEDS_REPORT_PAGE = REPORT_PAGE + '/needs';
export const MOVE_LIMIT_PAGE = '/movelimits';

export class RouteUrl {
	public static biddingClaimViewUrl(id: string): string {
		return RouteHelper.generateRouteWithId({
			routeUrl: RouteHelper.generateViewRoute(BIDDING_CLAIM_ROUTE),
			id,
		});
	}

	public static biddingClaimEditorUrl(id: string): string {
		return RouteHelper.generateRouteWithId({
			routeUrl: RouteHelper.generateEditorRoute(BIDDING_CLAIM_ROUTE),
			id,
		});
	}

	public static biddingClaimRetradeUrl(id: string): string {
		return RouteHelper.generateRouteWithId({
			routeUrl: BIDDING_CLAIM_RETRADE_ROUTE,
			id,
		});
	}

	public static biddingClaimEmergencyUrl(bidEmergencyId: string): string {
		return RouteHelper.generateRouteWithIds({
			routeUrl: BIDDING_CLAIM_EMERGENCY_ROUTE,
			ids: [bidEmergencyId],
		});
	}

	public static analysisBiddingClaimListViewUrl(biddingClaimId: string): string {
		return RouteHelper.generateRouteWithIds({
			routeUrl: ANALYSIS_BIDDING_CLAIM_ROUTE,
			ids: [biddingClaimId],
		});
	}

	public static analysisBiddingClaimEditorUrl(biddingClaimId: string, materialId: string): string {
		return RouteHelper.generateRouteWithIds({
			routeUrl: RouteHelper.generateEditorRoute(RouteUrl.analysisBiddingClaimListViewUrl(biddingClaimId)),
			ids: [materialId],
		});
	}

	public static needEditorUrl(id: string): string {
		return RouteHelper.generateRouteWithId({
			routeUrl: RouteHelper.generateEditorRoute(NEED_ROUTE),
			id,
		});
	}

	public static needViewUrl(id: string): string {
		return RouteHelper.generateRouteWithId({
			routeUrl: RouteHelper.generateViewRoute(NEED_ROUTE),
			id,
		});
	}

	public static needVolmaMaterialSupportUrl(needId: string): string {
		return RouteHelper.generateRouteWithIds({
			routeUrl: NEED_VOLMA_MATERIAL_SUPPORT_ROUTE,
			ids: [needId],
		});
	}

	public static needCopyUrl(needId: string): string {
		return RouteHelper.generateRouteWithIds({
			routeUrl: NEED_COPY_ROUTE,
			ids: [needId],
		});
	}

	public static orderFormPageUrl(biddingClaimId: string): string {
		return RouteUrl.analysisBiddingClaimListViewUrl(biddingClaimId) + ORDER_ROUTE;
	}

	public static orderCreatePageUrl(biddingClaimId: string): string {
		return RouteHelper.generateCreateRoute(RouteUrl.orderFormPageUrl(biddingClaimId));
	}

	public static orderEditPageUrl(biddingClaimId: string, orderId: string): string {
		return RouteHelper.generateRouteWithIds({
			routeUrl: RouteHelper.generateEditorRoute(RouteUrl.orderFormPageUrl(biddingClaimId)),
			ids: [orderId],
		});
	}

	public static orderViewPageUrl(id: string): string {
		return RouteHelper.generateRouteWithId({
			routeUrl: RouteHelper.generateViewRoute(ORDER_ROUTE),
			id,
		});
	}

	public static bidOfferCreateUrl(biddingClaimId: string): string {
		return RouteHelper.generateRouteWithIds({
			routeUrl: RouteHelper.generateCreateRoute(BID_OFFER_ROUTE),
			ids: [biddingClaimId],
		});
	}

	public static bidOfferEditorUrl(biddingClaimId: string): string {
		return RouteHelper.generateRouteWithIds({
			routeUrl: RouteHelper.generateEditorRoute(BID_OFFER_ROUTE),
			ids: [biddingClaimId],
		});
	}

	public static bidEmergencyEditorUrl(bidEmergencyId: string): string {
		return RouteHelper.generateRouteWithIds({
			routeUrl: RouteHelper.generateEditorRoute(BID_EMERGENCY_ROUTE),
			ids: [bidEmergencyId],
		});
	}

	public static bidEmergencyViewUrl(bidEmergencyId: string): string {
		return RouteHelper.generateRouteWithId({
			routeUrl: RouteHelper.generateViewRoute(BID_EMERGENCY_ROUTE),
			id: bidEmergencyId,
		});
	}

	public static materialViewUrl(id: string): string {
		return RouteHelper.generateRouteWithId({
			routeUrl: RouteHelper.generateViewRoute(MATERIAL_ROUTE),
			id,
		});
	}

	public static materialEditorUrl(id: string): string {
		return RouteHelper.generateRouteWithId({
			routeUrl: RouteHelper.generateEditorRoute(MATERIAL_ROUTE),
			id,
		});
	}

	public static materialGroupViewUrl(id: string): string {
		return RouteHelper.generateRouteWithId({
			routeUrl: RouteHelper.generateViewRoute(MATERIAL_GROUP_ROUTE),
			id,
		});
	}

	public static materialGroupEditorUrl(id: string): string {
		return RouteHelper.generateRouteWithId({
			routeUrl: RouteHelper.generateEditorRoute(MATERIAL_GROUP_ROUTE),
			id,
		});
	}

	public static materialTypeViewUrl(id: string): string {
		return RouteHelper.generateRouteWithId({
			routeUrl: RouteHelper.generateViewRoute(MATERIAL_TYPE_ROUTE),
			id,
		});
	}

	public static factoryViewUrl(id: string): string {
		return RouteHelper.generateRouteWithId({
			routeUrl: RouteHelper.generateViewRoute(FACTORY_ROUTE),
			id,
		});
	}

	public static factoryEditorUrl(id: string): string {
		return RouteHelper.generateRouteWithId({
			routeUrl: RouteHelper.generateEditorRoute(FACTORY_ROUTE),
			id,
		});
	}

	public static factoryLimitEditorUrl(id: string): string {
		return RouteHelper.generateRouteWithId({
			routeUrl: RouteHelper.generateEditorRoute(FACTORY_LIMIT_ROUTE),
			id,
		});
	}

	public static factoryLimitViewUrl(id: string): string {
		return RouteHelper.generateRouteWithId({
			routeUrl: RouteHelper.generateViewRoute(FACTORY_LIMIT_ROUTE),
			id,
		});
	}

	public static financialPositionViewUrl(id: string): string {
		return RouteHelper.generateRouteWithId({
			routeUrl: RouteHelper.generateViewRoute(FINANCIAL_POSITION_ROUTE),
			id,
		});
	}

	public static financialPositionLimitEditorUrl(id: string): string {
		return RouteHelper.generateRouteWithId({
			routeUrl: RouteHelper.generateEditorRoute(FINANCIAL_POSITION_LIMIT_ROUTE),
			id,
		});
	}

	public static financialPositionLimitViewUrl(id: string): string {
		return RouteHelper.generateRouteWithId({
			routeUrl: RouteHelper.generateViewRoute(FINANCIAL_POSITION_LIMIT_ROUTE),
			id,
		});
	}

	public static financialManagementUnitViewUrl(id: string): string {
		return RouteHelper.generateRouteWithId({
			routeUrl: RouteHelper.generateViewRoute(FINANCIAL_MANAGEMENT_UNIT_ROUTE),
			id,
		});
	}

	public static financialManagementUnitLimitEditorUrl(id: string): string {
		return RouteHelper.generateRouteWithId({
			routeUrl: RouteHelper.generateEditorRoute(FINANCIAL_MANAGEMENT_UNIT_LIMIT_ROUTE),
			id,
		});
	}

	public static financialManagementUnitLimitViewUrl(id: string): string {
		return RouteHelper.generateRouteWithId({
			routeUrl: RouteHelper.generateViewRoute(FINANCIAL_MANAGEMENT_UNIT_LIMIT_ROUTE),
			id,
		});
	}

	public static localizationEditorUrl(id: string): string {
		return RouteHelper.generateRouteWithId({
			routeUrl: RouteHelper.generateEditorRoute(LOCALIZATION_ROUTE),
			id,
		});
	}

	public static supplierEditorUrl(id: string): string {
		return RouteHelper.generateRouteWithId({
			routeUrl: RouteHelper.generateEditorRoute(SUPPLIER_ROUTE),
			id,
		});
	}
}
