import { EUserRole } from '../../dto/enum/EUserRole';
import { AccessRuleService } from './AccessRuleService';

export class SupplierAccessRuleService extends AccessRuleService {
	protected _canViewRoles = [
		this.roleService.getAllRolesMaskExcluding([
			EUserRole.VolmaService,
			EUserRole.VolmaEmployee,
			EUserRole.VolmaLeadEngineer,
			EUserRole.VolmaProductionDirector,
			EUserRole.CustomerContactPerson,
			EUserRole.CustomerEmployee,
		]),
	];
	protected _canViewInfoRoles = [EUserRole.CustomerContactPerson, EUserRole.CustomerEmployee];
	protected _canEditInfoRoles = [EUserRole.CustomerContactPerson];
	protected _canCreateRoles = [];
	protected _canDeleteRoles = [];
	protected _canEditRoles = [EUserRole.VolmaService];
	protected readonly _changeSapCodeRoles = [
		EUserRole.VolmaAdministrator,
		EUserRole.VolmaPurchasingManager,
		EUserRole.VolmaPurchaseServiceManager,
	];
	protected readonly _editSupplierMetrics = [
		EUserRole.VolmaAdministrator,
		EUserRole.VolmaPurchasingManager,
		EUserRole.VolmaPurchaseServiceManager,
	];
	protected readonly _editSupplierComplaints = [
		EUserRole.VolmaAdministrator,
		EUserRole.VolmaPurchasingManager,
		EUserRole.VolmaPurchaseServiceManager,
	];
	protected _canBlockSupplierRoles = [EUserRole.VolmaAdministrator, EUserRole.VolmaPurchasingManager];
	protected _canRestrictSupplierRoles = [
		EUserRole.VolmaAdministrator,
		EUserRole.VolmaPurchasingManager,
		EUserRole.VolmaPurchaseServiceManager,
	];
	protected _canUnblockSupplierRoles = this._canBlockSupplierRoles;

	public canChangeSupplierCode = (): boolean => {
		return this._hasRole(this._changeSapCodeRoles);
	};

	public canEditSupplierMetrics = (): boolean => {
		return this._hasRole(this._editSupplierMetrics);
	};

	public canEditSupplierComplaints = (): boolean => {
		return this._hasRole(this._editSupplierComplaints);
	};

	public canViewInfo = (): boolean => {
		return this._hasRole(this._canViewInfoRoles);
	};

	public canEditInfo = (): boolean => {
		return this._hasRole(this._canEditInfoRoles);
	};

	public canBlockSupplier = (): boolean => {
		return this._hasRole(this._canBlockSupplierRoles);
	};

	public canRestrictSupplier = (): boolean => {
		return this._hasRole(this._canRestrictSupplierRoles);
	};

	public canUnblockSupplier = (): boolean => {
		return this._hasRole(this._canUnblockSupplierRoles);
	};
}
