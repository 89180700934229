import { Container } from 'inversify';
import 'reflect-metadata';
import { UrlFabric } from '../../shared/constants/UrlFabric';
import { AdminApi } from '../api/AdminApi';
import { AuthUserApi } from '../api/AuthUserApi';
import { BiddingClaimApi } from '../api/BiddingClaimApi';
import { BidEmergencyApi } from '../api/BidEmergencyApi';
import { BidOfferApi } from '../api/BidOfferApi';
import { FactoryLimitApi } from '../api/FactoryLimitApi';
import { FileApi } from '../api/FileApi';
import { FinancialManagementUnitApi } from '../api/FinancialManagementUnitApi';
import { FinancialManagementUnitLimitApi } from '../api/FinancialManagementUnitLimitApi';
import { FinancialPositionApi } from '../api/FinancialPositionApi';
import { FinancialPositionLimitApi } from '../api/FinancialPositionLimitApi';
import { LocaleApi } from '../api/LocaleApi';
import { MaterialApi } from '../api/MaterialApi';
import { MaterialGroupApi } from '../api/MaterialGroupApi';
import { MaterialTypeApi } from '../api/MaterialTypeApi';
import { NeedApi } from '../api/NeedApi';
import { OrderApi } from '../api/OrderApi';
import { PurchaseOrganizationApi } from '../api/PurchaseOrganizationApi';
import { ReportApi } from '../api/ReportApi';
import { SettingsApi } from '../api/SettingsApi';
import { SupplierApi } from '../api/SupplierApi';
import { TransportKindApi } from '../api/TransportKindApi';
import { UnloadTypeApi } from '../api/UnloadTypeApi';
import { UserApi } from '../api/UserApi';
import { AnalysisBiddingClaimAccessRuleService } from '../services/accessRule/AnalysisBiddingClaimRuleService';
import { BiddingClaimAccessRuleService } from '../services/accessRule/BiddingClaimAccessRuleService';
import { BidEmergencyAccessRuleService } from '../services/accessRule/BidEmergencyAccessRuleService';
import { BidOfferAccessRuleService } from '../services/accessRule/BidOfferAccessRuleService';
import { FactoryAccessRuleService } from '../services/accessRule/FactoryAccessRuleService';
import { FactoryLimitAccessRuleService } from '../services/accessRule/FactoryLimitAccessRuleService';
import { FinancialManagementUnitAccessRuleService } from '../services/accessRule/FinancialManagementUnitAccessRuleService';
import { FinancialManagementUnitLimitAccessRuleService } from '../services/accessRule/FinancialManagementUnitLimitAccessRuleService';
import { FinancialPositionAccessRuleService } from '../services/accessRule/FinancialPositionAccessRuleService';
import { FinancialPositionLimitAccessRuleService } from '../services/accessRule/FinancialPositionLimitAccessRuleService';
import { LocalizationAccessRuleService } from '../services/accessRule/LocalizationAccessRuleService';
import { MaterialAccessRuleService } from '../services/accessRule/MaterialAccessRuleService';
import { MaterialGroupAccessRuleService } from '../services/accessRule/MaterialGroupAccessRuleService';
import { MaterialTypeAccessRuleService } from '../services/accessRule/MaterialTypeAccessRuleService';
import { MoveLimitsAccessRuleService } from '../services/accessRule/MoveLimitsAccessRuleService';
import { NeedAccessRuleService } from '../services/accessRule/NeedAccessRuleService';
import { OrderAccessRuleService } from '../services/accessRule/OrderAccessRuleService';
import { PurchaseOrganizationAccessRuleService } from '../services/accessRule/PurchaseOrganizationAccessRuleService';
import { ReportAccessRuleService } from '../services/accessRule/ReportAccessRuleService';
import { SettingsAccessRuleService } from '../services/accessRule/SettingsAccessRuleService';
import { SupplierAccessRuleService } from '../services/accessRule/SupplierAccessRuleService';
import { UnloadTypeAccessRuleService } from '../services/accessRule/UnloadTypeAccessRuleService';
import { UserAccessRuleService } from '../services/accessRule/UserAccessRuleService';
import { BidEmergencyApprover } from '../services/approvers/BidEmergency/BidEmergencyApprover';
import { FactoryLimitApprover } from '../services/approvers/Limits/FactoryLimitApprover';
import { FinancialManagementUnitLimitApprover } from '../services/approvers/Limits/FinancialManagementUnitLimitApprover';
import { FinancialPositionLimitApprover } from '../services/approvers/Limits/FinancialPositionLimitApprover';
import { MaterialApprover } from '../services/approvers/Material/MaterialApprover';
import { MoveLimitApprover } from '../services/approvers/MoveLimit/MoveLimitApprover';
import { NeedApprover } from '../services/approvers/Need/NeedApprover';
import { SupplierApprover } from '../services/approvers/Supplier/SupplierApprover';
import { UserApprover } from '../services/approvers/User/UserApprover';
import { AuthService } from '../services/auth/AuthService';
import { ValidationService } from '../services/validation/ValidationService';
import { BiddingClaimWorkflowStateOrderer } from '../services/workflowStatuses/BiddingClaim/BiddingClaimWorkflowStateOrderer';
import { BidEmergencyWorkflowStateOrderer } from '../services/workflowStatuses/BidEmergency/BidEmergencyWorkflowStateOrderer';
import { NeedWorkflowStateOrderer } from '../services/workflowStatuses/Need/NeedWorkflowStatusOrderer';
import { OrderWorkflowStateOrderer } from '../services/workflowStatuses/Order/OrderWorkflowStateOrderer';
import { FactoryApi } from './../api/FactoryApi';
import { BiddingClaimApprover } from './../services/approvers/BiddingClaim/BiddingClaimApprover';
import { BidOfferApprover } from './../services/approvers/BidOffer/BidOfferApprover';
import { OrderApprover } from './../services/approvers/Order/OrderApprover';
import { FileService } from './../services/file/FileService';
import { NotificationService } from './../services/notifcation/notificationService';
import { RoleService } from './../services/role/RoleService';
import { TranslationService } from './../services/translation/TranslationService';
import { Types } from './types';

const Kernel = new Container();

Kernel.bind<UrlFabric>(Types.UrlFabric).to(UrlFabric);
Kernel.bind<BiddingClaimApi>(Types.BiddingClaimApi).to(BiddingClaimApi);
Kernel.bind<FactoryApi>(Types.FactoryApi).to(FactoryApi);
Kernel.bind<OrderApi>(Types.OrderApi).to(OrderApi);
Kernel.bind<SupplierApi>(Types.SupplierApi).to(SupplierApi);
Kernel.bind<MaterialApi>(Types.MaterialApi).to(MaterialApi);
Kernel.bind<MaterialGroupApi>(Types.MaterialGroupApi).to(MaterialGroupApi);
Kernel.bind<MaterialTypeApi>(Types.MaterialTypeApi).to(MaterialTypeApi);
Kernel.bind<FinancialPositionApi>(Types.FinancialPositionApi).to(FinancialPositionApi);
Kernel.bind<FinancialManagementUnitApi>(Types.FinancialManagementUnitApi).to(FinancialManagementUnitApi);
Kernel.bind<AuthUserApi>(Types.AuthUserApi).to(AuthUserApi);
Kernel.bind<UserApi>(Types.UserApi).to(UserApi);
Kernel.bind<FactoryLimitApi>(Types.FactoryLimitApi).to(FactoryLimitApi);
Kernel.bind<FinancialPositionLimitApi>(Types.FinancialPositionLimitApi).to(FinancialPositionLimitApi);
Kernel.bind<FinancialManagementUnitLimitApi>(Types.FinancialManagementUnitLimitApi).to(FinancialManagementUnitLimitApi);
Kernel.bind<FileApi>(Types.FileApi).to(FileApi);
Kernel.bind<SettingsApi>(Types.SettingsApi).to(SettingsApi);
Kernel.bind<BidOfferApi>(Types.BidOfferApi).to(BidOfferApi);
Kernel.bind<BidEmergencyApi>(Types.BidEmergencyApi).to(BidEmergencyApi);
Kernel.bind<PurchaseOrganizationApi>(Types.PurchaseOrganizationApi).to(PurchaseOrganizationApi);
Kernel.bind<AdminApi>(Types.AdminApi).to(AdminApi);
Kernel.bind<NeedApi>(Types.NeedApi).to(NeedApi);
Kernel.bind<UnloadTypeApi>(Types.UnloadTypeApi).to(UnloadTypeApi);
Kernel.bind<TransportKindApi>(Types.TransportKindApi).to(TransportKindApi);
Kernel.bind<LocaleApi>(Types.LocaleApi).to(LocaleApi);
Kernel.bind<ReportApi>(Types.ReportApi).to(ReportApi);
Kernel.bind<AuthService>(Types.AuthService).to(AuthService).inSingletonScope();
Kernel.bind<TranslationService>(Types.TranslationService).to(TranslationService).inSingletonScope();
Kernel.bind<NotificationService>(Types.NotificationService).to(NotificationService).inSingletonScope();
Kernel.bind<MaterialApprover>(Types.MaterialApprover).to(MaterialApprover);
Kernel.bind<OrderApprover>(Types.OrderApprover).to(OrderApprover);
Kernel.bind<BiddingClaimApprover>(Types.BiddingClaimApprover).to(BiddingClaimApprover);
Kernel.bind<BidOfferApprover>(Types.BidOfferApprover).to(BidOfferApprover);
Kernel.bind<BidEmergencyApprover>(Types.BidEmergencyApprover).to(BidEmergencyApprover);
Kernel.bind<NeedApprover>(Types.NeedApprover).to(NeedApprover);
Kernel.bind<SupplierApprover>(Types.SupplierApprover).to(SupplierApprover);
Kernel.bind<UserApprover>(Types.UserApprover).to(UserApprover);
Kernel.bind<FactoryLimitApprover>(Types.FactoryLimitApprover).to(FactoryLimitApprover);
Kernel.bind<FinancialManagementUnitLimitApprover>(Types.FinancialManagementUnitLimitApprover).to(
	FinancialManagementUnitLimitApprover,
);
Kernel.bind<FinancialPositionLimitApprover>(Types.FinancialPositionLimitApprover).to(FinancialPositionLimitApprover);
Kernel.bind<MoveLimitApprover>(Types.MoveLimitApprover).to(MoveLimitApprover);
Kernel.bind<SupplierAccessRuleService>(Types.SupplierAccessRuleService)
	.to(SupplierAccessRuleService)
	.inSingletonScope();
Kernel.bind<UserAccessRuleService>(Types.UserAccessRuleService).to(UserAccessRuleService).inSingletonScope();
Kernel.bind<FactoryAccessRuleService>(Types.FactoryAccessRuleService).to(FactoryAccessRuleService).inSingletonScope();
Kernel.bind<FinancialManagementUnitAccessRuleService>(Types.FinancialManagementUnitAccessRuleService)
	.to(FinancialManagementUnitAccessRuleService)
	.inSingletonScope();
Kernel.bind<FinancialPositionAccessRuleService>(Types.FinancialPositionAccessRuleService)
	.to(FinancialPositionAccessRuleService)
	.inSingletonScope();
Kernel.bind<FactoryLimitAccessRuleService>(Types.FactoryLimitAccessRuleService).to(FactoryLimitAccessRuleService);
Kernel.bind<FinancialManagementUnitLimitAccessRuleService>(Types.FinancialManagementUnitLimitAccessRuleService)
	.to(FinancialManagementUnitLimitAccessRuleService)
	.inSingletonScope();
Kernel.bind<FinancialPositionLimitAccessRuleService>(Types.FinancialPositionLimitAccessRuleService)
	.to(FinancialPositionLimitAccessRuleService)
	.inSingletonScope();
Kernel.bind<MoveLimitsAccessRuleService>(Types.MoveLimitsAccessRuleService)
	.to(MoveLimitsAccessRuleService)
	.inSingletonScope();
Kernel.bind<MaterialAccessRuleService>(Types.MaterialAccessRuleService)
	.to(MaterialAccessRuleService)
	.inSingletonScope();
Kernel.bind<MaterialTypeAccessRuleService>(Types.MaterialTypeAccessRuleService)
	.to(MaterialTypeAccessRuleService)
	.inSingletonScope();
Kernel.bind<MaterialGroupAccessRuleService>(Types.MaterialGroupAccessRuleService)
	.to(MaterialGroupAccessRuleService)
	.inSingletonScope();
Kernel.bind<NeedAccessRuleService>(Types.NeedAccessRuleService).to(NeedAccessRuleService).inSingletonScope();
Kernel.bind<BidEmergencyAccessRuleService>(Types.BidEmergencyAccessRuleService)
	.to(BidEmergencyAccessRuleService)
	.inSingletonScope();
Kernel.bind<BiddingClaimAccessRuleService>(Types.BiddingClaimAccessRuleService)
	.to(BiddingClaimAccessRuleService)
	.inSingletonScope();
Kernel.bind<AnalysisBiddingClaimAccessRuleService>(Types.AnalysisBiddingClaimAccessRuleService)
	.to(AnalysisBiddingClaimAccessRuleService)
	.inSingletonScope();
Kernel.bind<BidOfferAccessRuleService>(Types.BidOfferAccessRuleService)
	.to(BidOfferAccessRuleService)
	.inSingletonScope();
Kernel.bind<OrderAccessRuleService>(Types.OrderAccessRuleService).to(OrderAccessRuleService).inSingletonScope();
Kernel.bind<SettingsAccessRuleService>(Types.SettingsAccessRuleService)
	.to(SettingsAccessRuleService)
	.inSingletonScope();
Kernel.bind<PurchaseOrganizationAccessRuleService>(Types.PurchaseOrganizationAccessRuleService)
	.to(PurchaseOrganizationAccessRuleService)
	.inSingletonScope();
Kernel.bind<LocalizationAccessRuleService>(Types.LocalizationAccessRuleService)
	.to(LocalizationAccessRuleService)
	.inSingletonScope();
Kernel.bind<UnloadTypeAccessRuleService>(Types.UnloadTypeAccessRuleService)
	.to(UnloadTypeAccessRuleService)
	.inSingletonScope();
Kernel.bind<ReportAccessRuleService>(Types.ReportAccessRuleService).to(ReportAccessRuleService).inSingletonScope();
Kernel.bind<FileService>(Types.FileService).to(FileService);
Kernel.bind<RoleService>(Types.RoleService).to(RoleService).inSingletonScope();
Kernel.bind<ValidationService>(Types.ValidationService).to(ValidationService);
Kernel.bind<NeedWorkflowStateOrderer>(Types.NeedWorkflowStateOrderer).to(NeedWorkflowStateOrderer).inSingletonScope();
Kernel.bind<OrderWorkflowStateOrderer>(Types.OrderWorkflowStateOrderer)
	.to(OrderWorkflowStateOrderer)
	.inSingletonScope();
Kernel.bind<BiddingClaimWorkflowStateOrderer>(Types.BiddingClaimWorkflowStateOrderer)
	.to(BiddingClaimWorkflowStateOrderer)
	.inSingletonScope();
Kernel.bind<BidEmergencyWorkflowStateOrderer>(Types.BidEmergencyWorkflowStateOrderer)
	.to(BidEmergencyWorkflowStateOrderer)
	.inSingletonScope();

export { Kernel };
