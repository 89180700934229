import { injectable } from 'inversify';
import { ReactNode } from 'react';
import { toast, ToastOptions, TypeOptions } from 'react-toastify';

@injectable()
export class NotificationService {
	public static readonly errorNotificationType = 'error';
	public static readonly successNotificationType = 'success';
	private static readonly notificationContainerPosition = 'top-right';
	private static readonly autoCloseDelayMs = 5000;
	private readonly _config: ToastOptions;

	constructor() {
		this._config = {
			draggable: false,
			position: NotificationService.notificationContainerPosition,
			autoClose: NotificationService.autoCloseDelayMs,
		};
	}

	public createNotification = (type: TypeOptions, message: ReactNode): void => {
		toast(message, { ...this._config, type });
	};
}
