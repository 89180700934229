import { AxiosPromise } from 'axios';
import { CreateFileDto } from '../dto/File/CreateFileDto';
import { Api } from './Api';

interface IStoreFile {
	id: string;
	chunk: FormData;
	onProgressChanged: (event: IProgressEvent) => void;
}

interface IProgressEvent {
	loaded: number;
}

export class FileApi extends Api {
	public store = (params: IStoreFile): AxiosPromise<void> => {
		return this.upload(this._urlFabric.storeFileChunk(params.id), params.chunk, {
			onUploadProgress: params.onProgressChanged,
		});
	};

	public createFile = (dto: CreateFileDto): AxiosPromise<string> => {
		return this.post(this._urlFabric.createFile(), dto);
	};

	public finish = (id: string): AxiosPromise<void> => {
		return this.post(this._urlFabric.finishFile(id), {});
	};

	public getFileSrcUrl = (id: string): string => {
		return this._urlFabric.loadFile(id);
	};

	public getFileBlob = (src: string): AxiosPromise<Blob> => {
		return this.get(src, { responseType: 'arraybuffer' });
	};
}
