import { EUserRole } from '../../dto/enum/EUserRole';
import { AccessRuleService } from './AccessRuleService';

export class BiddingClaimAccessRuleService extends AccessRuleService {
	protected _canViewRoles = [
		this.roleService.getAllRolesMaskExcluding([
			EUserRole.VolmaEmployee,
			EUserRole.VolmaService,
			EUserRole.VolmaMaterialDictionaryEditor,
		]),
	];
	protected _canCreateRoles = [EUserRole.VolmaAdministrator, EUserRole.VolmaPurchasingManager];
	protected _canDeleteRoles = [];
	protected _canEditRoles = [EUserRole.VolmaAdministrator, EUserRole.VolmaPurchasingManager];
	protected _canStopBiddingClaimRoles = [EUserRole.VolmaAdministrator];
	protected _canRetradeRoles = [EUserRole.VolmaAdministrator, EUserRole.VolmaPurchasingManager];
	protected _canViewStateHistoryRoles = [
		this.roleService.getAllRolesMaskExcluding([EUserRole.CustomerContactPerson, EUserRole.CustomerEmployee]),
	];

	public canStopBiddingClaim = (): boolean => {
		return this._hasRole(this._canStopBiddingClaimRoles);
	};

	public canRetrade = (): boolean => {
		return this._hasRole(this._canRetradeRoles);
	};

	public canViewCreatedOfferState = (): boolean => {
		return this.isSupplierUser();
	};

	public canViewHistoryStateTable = (): boolean => {
		return this._hasRole(this._canViewStateHistoryRoles);
	};
}
