import { EUserRole } from '../../dto/enum/EUserRole';
import { OrderViewExtendedDto } from '../../models/Order/OrderViewExtendedDto';
import { ArrayHelper } from '../../utils/helpers/ArrayHelper';
import { AccessRuleService } from './AccessRuleService';

export class OrderAccessRuleService extends AccessRuleService {
	protected _canViewRoles = [
		this.roleService.getAllRolesMaskExcluding([
			EUserRole.VolmaEmployee,
			EUserRole.VolmaLeadEngineer,
			EUserRole.VolmaService,
			EUserRole.VolmaMaterialDictionaryEditor,
		]),
	];
	protected readonly _canSendOrderToAgreementRoles = [
		EUserRole.VolmaAdministrator,
		EUserRole.VolmaMaterialSupport,
		EUserRole.VolmaPurchasingManager,
		EUserRole.VolmaPurchaseServiceManager,
	];
	protected readonly _canAgreeBySupplierRoles = [EUserRole.CustomerContactPerson, EUserRole.CustomerEmployee];
	protected readonly _canRetrySapRoles = [
		EUserRole.VolmaAdministrator,
		EUserRole.VolmaMaterialSupport,
		EUserRole.VolmaPurchasingManager,
	];

	protected _canCreateRoles = [
		EUserRole.VolmaMaterialSupport,
		EUserRole.VolmaPurchaseServiceManager,
		EUserRole.VolmaPurchasingManager,
		EUserRole.VolmaAdministrator,
	];
	protected _canDeleteRoles = [];
	protected _canEditRoles = [
		EUserRole.VolmaMaterialSupport,
		EUserRole.VolmaPurchaseServiceManager,
		EUserRole.VolmaPurchasingManager,
		EUserRole.VolmaAdministrator,
	];
	protected _canAgreeOrderRoles(record: OrderViewExtendedDto): EUserRole[] {
		const rawMaterialsOrderRoles = [EUserRole.VolmaPurchaseServiceManager];
		const materialsOrderRoles = [
			EUserRole.VolmaFinancialControlOfficer,
			EUserRole.VolmaPurchaseServiceManager,
			EUserRole.VolmaProductionDirector,
		];
		const mergedMaterialOrderRoles = ArrayHelper.mergeArraysWithoutDuplicates<EUserRole>(
			rawMaterialsOrderRoles,
			materialsOrderRoles,
		);

		return record.hasRawMaterials && record.hasNotRawMaterials
			? mergedMaterialOrderRoles
			: record.hasRawMaterials
			? rawMaterialsOrderRoles
			: materialsOrderRoles;
	}
	protected _canViewStateHistoryRoles = [
		this.roleService.getAllRolesMaskExcluding([
			EUserRole.VolmaEmployee,
			EUserRole.CustomerContactPerson,
			EUserRole.CustomerEmployee,
		]),
	];

	protected _canViewOrderApproversRoles = [
		this.roleService.getAllRolesMaskExcluding([EUserRole.CustomerContactPerson, EUserRole.CustomerEmployee]),
	];

	public canSendOrderToAgreement = (): boolean => {
		return this._hasRole(this._canSendOrderToAgreementRoles);
	};

	public canAgreeOrdersBySupplier = (): boolean => {
		return this._hasRole(this._canAgreeBySupplierRoles);
	};

	public canAgreeOrder = (record: OrderViewExtendedDto): boolean => {
		return this._hasRole(this._canAgreeOrderRoles(record));
	};

	public canRetrySap = (): boolean => {
		return this._hasRole(this._canRetrySapRoles);
	};

	public canViewHistoryStateTable = (): boolean => {
		return this._hasRole(this._canViewStateHistoryRoles);
	};

	public canViewOrderApproversTable = (): boolean => {
		return this._hasRole(this._canViewOrderApproversRoles);
	};
}
