import { EBidEmergencyWorkflowState } from '../../../dto/enum/EBidEmergencyWorkflowState';
import { BaseWorkflowStateOrderer } from '../BaseWorkflowStateOrderer';

export class BidEmergencyWorkflowStateOrderer extends BaseWorkflowStateOrderer {
	protected _ordinaryStates = [
		EBidEmergencyWorkflowState.Draft,
		EBidEmergencyWorkflowState.Revision,
		EBidEmergencyWorkflowState.New,
		EBidEmergencyWorkflowState.ApprovedByEngineer,
		EBidEmergencyWorkflowState.ApprovedByDirector,
		EBidEmergencyWorkflowState.BiddingClaimCreated,
	];
	protected _terminatedStates = [EBidEmergencyWorkflowState.Cancelled];
}
