import Axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import { injectable } from 'inversify';
import queryString from 'qs';
import { UrlFabric } from '../../shared/constants/UrlFabric';
import { useInject } from '../hooks/useInject';
import { Types } from '../inversify/types';
import { AuthService } from '../services/auth/AuthService';

@injectable()
export class Api {
	public _urlFabric: UrlFabric;
	private _authService: AuthService;
	private _axiosClient: AxiosInstance;
	private readonly _axiosRequestOptions: AxiosRequestConfig = {
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		timeout: 120000,
	};

	constructor() {
		this._urlFabric = useInject<UrlFabric>(Types.UrlFabric);
		this._authService = useInject<AuthService>(Types.AuthService);

		this._axiosClient = Axios.create(this._axiosRequestOptions);
		this._axiosClient.interceptors.request.use(this._setAccessTokenOnRequest);
	}

	private _setAccessTokenOnRequest = (request: AxiosRequestConfig): AxiosRequestConfig => {
		const accessToken = this._authService.getAccessToken();
		request.headers['Authorization'] = `Bearer ${accessToken}`;

		return request;
	};

	protected get<T>(url: string, axiosConfigs?: AxiosRequestConfig): AxiosPromise<T> {
		return this._axiosClient.get(url, axiosConfigs);
	}

	protected list<T>(url: string, parameters?: any): AxiosPromise<T> {
		const bakedUrl = parameters ? url + '?' + queryString.stringify(parameters) : url;
		return this._axiosClient.get(bakedUrl);
	}

	protected post<T>(url: string, item: any, config?: AxiosRequestConfig): AxiosPromise<T> {
		return this._axiosClient.post(url, item, config);
	}

	protected put<T, TResponse = void>(url: string, item: T): AxiosPromise<TResponse> {
		return this._axiosClient.put(url, item);
	}

	protected delete<T>(url: string): AxiosPromise<void> {
		return this._axiosClient.delete(url);
	}

	protected upload<T>(url: string, formData: FormData, config?: AxiosRequestConfig): AxiosPromise<T> {
		return this.post<T>(url, formData, {
			...config,
			headers: { 'Content-Type': 'multipart/form-data' },
		});
	}
}
