import { configure } from 'mobx';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Loader } from './app/components/shared/loader/Loader';
import { useInject } from './app/hooks/useInject';
import { Types } from './app/inversify/types';
import { TranslationService } from './app/services/translation/TranslationService';
import './assets/styles/styles.scss';
import * as serviceWorker from './serviceWorker';

const App = React.lazy(() => import('./App'));

configure({ enforceActions: 'observed' });

useInject<TranslationService>(Types.TranslationService)
	.init()
	.then(() => {
		ReactDOM.render(
			<Suspense fallback={<Loader isLoading={true} />}>
				<App />
			</Suspense>,
			document.getElementById('root'),
		);
	});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
