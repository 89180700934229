import { injectable } from 'inversify';
import { EUserRole } from '../../dto/enum/EUserRole';
import { useInject } from '../../hooks/useInject';
import { Types } from '../../inversify/types';
import { RoleService } from '../role/RoleService';

@injectable()
export abstract class AccessRuleService {
	protected abstract readonly _canViewRoles: EUserRole[];
	protected abstract readonly _canEditRoles: EUserRole[];
	protected abstract readonly _canDeleteRoles: EUserRole[];
	protected abstract readonly _canCreateRoles: EUserRole[];
	private readonly _customerRoles = [EUserRole.CustomerContactPerson, EUserRole.CustomerEmployee];

	protected readonly roleService: RoleService = useInject(Types.RoleService);

	protected _hasRole = (acceptableRoles: EUserRole[]): boolean => {
		return this.roleService.hasAcceptableRole(acceptableRoles);
	};

	public canCreate = (): boolean => {
		return this._hasRole(this._canCreateRoles);
	};

	public canView = (): boolean => {
		return this._hasRole(this._canViewRoles);
	};

	public canEdit = (): boolean => {
		return this._hasRole(this._canEditRoles);
	};

	public canDelete = (): boolean => {
		return this._hasRole(this._canDeleteRoles);
	};

	public isSupplierUser = (): boolean => {
		return this._hasRole(this._customerRoles);
	};
}
