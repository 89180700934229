import { AxiosPromise } from 'axios';
import { BiddingSettingsDto } from '../dto/Settings/BiddingSettingsDto';
import { Api } from './Api';

export class SettingsApi extends Api {
	public getBiddingSettings(): AxiosPromise<BiddingSettingsDto> {
		const url = this._urlFabric.getBiddingSettings();

		return this.get(url, {});
	}

	public updateBiddingSettings(dto: BiddingSettingsDto): AxiosPromise<void> {
		const url = this._urlFabric.getBiddingSettings();

		return this.put(url, dto);
	}
}
