import { AxiosPromise } from 'axios';
import { TableApi } from './TableApi';

export abstract class LimitApi extends TableApi {
	public getStatictic<TStatisticRequestDto, TStatisticResponseDto>(
		dto: TStatisticRequestDto,
	): AxiosPromise<TStatisticResponseDto> {
		const url = this._urlFabric.getLimitStatistic(this.tableUrl);

		return this.get(url, { params: dto });
	}
}
