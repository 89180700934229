import { BiddingClaimTableItemDto } from '../../../dto/BiddingClaim/BiddingClaimTableItemDto';
import { EBiddingClaimState } from '../../../dto/enum/EBiddingClaimState';
import { ESupplierWorkflowState } from '../../../dto/enum/ESupplierWorkflowState';
import { UserDto } from '../../../dto/User/UserDto';
import { useInject } from '../../../hooks/useInject';
import { Types } from '../../../inversify/types';
import { StringComparer } from '../../../utils/comparers/StringComparer';
import { AnalysisBiddingClaimAccessRuleService } from '../../accessRule/AnalysisBiddingClaimRuleService';
import { BiddingClaimAccessRuleService } from '../../accessRule/BiddingClaimAccessRuleService';
import { BidOfferAccessRuleService } from '../../accessRule/BidOfferAccessRuleService';
import { BaseApprover } from '../BaseApprover';
import { BiddingClaimEditorDto } from './../../../dto/BiddingClaim/BiddingClaimEditorDto';
import { isValueDefined } from './../../../utils/Utils';

export class BiddingClaimApprover extends BaseApprover {
	private readonly _biddingClaimAccessRuleService = useInject<BiddingClaimAccessRuleService>(
		Types.BiddingClaimAccessRuleService,
	);
	private readonly _analysisBiddingClaimAccessRuleService = useInject<AnalysisBiddingClaimAccessRuleService>(
		Types.AnalysisBiddingClaimAccessRuleService,
	);
	private readonly _bidOfferAccessRuleService = useInject<BidOfferAccessRuleService>(Types.BidOfferAccessRuleService);

	public canEdit = (user: UserDto | undefined, biddingClaim: BiddingClaimTableItemDto): boolean => {
		const acceptableStatuses = [EBiddingClaimState.New];
		const hasAcceptableStatus = this.hasAcceptableStatus(biddingClaim.state, acceptableStatuses);
		const isCreator = StringComparer.compare(biddingClaim.createdById, user?.id);
		const notStarted = new Date(biddingClaim.startDate) > new Date();

		return isCreator && hasAcceptableStatus && notStarted;
	};

	public canStopBid = (user: UserDto | undefined, biddingClaim: BiddingClaimEditorDto | undefined): boolean => {
		const hasAcceptableRole = this._biddingClaimAccessRuleService.canStopBiddingClaim();
		const hasAcceptableStatus = this.hasAcceptableStatus(biddingClaim?.state, [EBiddingClaimState.Process]);

		return hasAcceptableRole && hasAcceptableStatus;
	};

	public canFormBidOffers = (user: UserDto | undefined, biddingClaim: BiddingClaimEditorDto | undefined): boolean => {
		const hasAcceptableRole = this._bidOfferAccessRuleService.canCreate();
		const hasAcceptableStatus = this.hasAcceptableStatus(biddingClaim?.state, [EBiddingClaimState.Process]);

		return (
			hasAcceptableRole &&
			hasAcceptableStatus &&
			(user?.supplierState === ESupplierWorkflowState.Admitted ||
				user?.supplierState === ESupplierWorkflowState.Limited)
		);
	};

	public canRetradeBid = (biddingClaim: BiddingClaimEditorDto | undefined): boolean => {
		const hasAcceptableRole = this._biddingClaimAccessRuleService.canRetrade();
		const hasAcceptableStatus = this.hasAcceptableStatus(biddingClaim?.state, [EBiddingClaimState.Completed]);

		return hasAcceptableRole && hasAcceptableStatus;
	};

	public canAnalyseBid = (biddingClaim: BiddingClaimEditorDto | undefined): boolean => {
		if (!biddingClaim) {
			return false;
		}

		return this.canAnalyseBidByStateAndOffersCount(biddingClaim?.state, biddingClaim?.bidOffersCount);
	};

	public canAnalyseBidByStateAndOffersCount = (state: EBiddingClaimState, bidOffersCount: number | null): boolean => {
		const hasAcceptableRole = this._analysisBiddingClaimAccessRuleService.canView();
		const hasAcceptableStatus = this.hasAcceptableStatus(state, [EBiddingClaimState.Completed]);
		const hasOffers = isValueDefined(bidOffersCount) && (bidOffersCount as number) > 0;

		return hasAcceptableRole && hasAcceptableStatus && hasOffers;
	};
}
