import { AxiosPromise } from 'axios';
import { DictionaryDto } from '../dto/Special/DictionaryDto';
import { TableDto } from '../dto/Special/TableDto';
import { FilterBuilder } from '../utils/helpers/FilterHelper';
import { Api } from './Api';

export abstract class TableApi extends Api {
	protected abstract tableUrl: string;
	protected readonly defaultTakeValue = 1000;

	protected getRecordUrl(id: string): string {
		return `${this.tableUrl}/${id}`;
	}

	protected getEditRecordUrl(id: string): string {
		return `${this.tableUrl}/${id}/edit`;
	}

	protected createRecordUrl(parentEntityId?: string): string {
		return this.tableUrl;
	}

	protected editRecordUrl(id: string | undefined): string {
		const idParam = id ? `/${id}` : '';
		return this.tableUrl + idParam;
	}

	protected listUrl(): string {
		return this.tableUrl;
	}

	protected historyUrl(id: string): string {
		return `${this.tableUrl}/${id}/history`;
	}

	protected dictionaryUrl(): string {
		return `${this.tableUrl}/dictionary`;
	}

	public table<TDto>(parameters: any, filters: FilterBuilder): AxiosPromise<TableDto<TDto>> {
		const filtersObject = filters ? { filters: filters.build() } : {};

		const newParams = {
			...parameters,
			...filtersObject,
		};

		return this.list<TableDto<TDto>>(this.listUrl(), newParams);
	}

	public getHistory<THistoryDto>(id: string, parameters: any): AxiosPromise<TableDto<THistoryDto>> {
		return this.list<TableDto<THistoryDto>>(this.historyUrl(id), parameters);
	}

	public getRecord<TDto>(id: string): AxiosPromise<TDto> {
		return this.get(this.getRecordUrl(id));
	}

	public getEditRecord<TDto>(id: string): AxiosPromise<TDto> {
		return this.get(this.getEditRecordUrl(id));
	}

	public createRecord<TDto, TResponse = void>(dto: TDto, parentEntityId?: string): AxiosPromise<TResponse> {
		return this.post(this.createRecordUrl(parentEntityId), dto);
	}

	public editRecord<TDto, TResponse = void>(dto: TDto, id: string | undefined): AxiosPromise<TResponse> {
		return this.put(this.editRecordUrl(id), dto);
	}

	public deleteRecord(id: string): AxiosPromise<void> {
		return this.delete(`${this.tableUrl}/${id}`);
	}

	public getDictionary<TDto>(filters?: FilterBuilder, take?: number): AxiosPromise<DictionaryDto<TDto>> {
		const filtersObject = filters ? { filters: filters.build() } : {};

		const params = {
			take: (take || this.defaultTakeValue).toString(),
			...filtersObject,
		};

		return this.list(this.dictionaryUrl(), params);
	}
}
