import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { BiddingClaimGroupingScheduleItemsDto } from '../dto/BiddingClaim/BiddingClaimGroupingScheduleItemsDto';
import { BiddingClaimSetCostAndConsumptionDto } from '../dto/BiddingClaim/BiddingClaimSetCostAndConsumptionDto';
import { SuppliersApproveDto } from '../dto/BiddingClaim/SuppliersApproveDto';
import { InitiationCreateBiddingClaimDto } from '../dto/BidOffer/InitiationCreateBiddingClaimDto';
import { OrderTableItemDto } from '../dto/Order/OrderTableItemDto';
import { DictionaryDto } from '../dto/Special/DictionaryDto';
import { TableDto } from '../dto/Special/TableDto';
import { SupplierInBiddingDto } from './../dto/Supplier/SupplierInBiddingDto';
import { TableApi } from './TableApi';

@injectable()
export class BiddingClaimApi extends TableApi {
	protected readonly tableUrl: string = this._urlFabric.biddingClaimsTable;

	public stopBiddingClaim(id: string): AxiosPromise<void> {
		const url = this._urlFabric.stopBiddingClaim(id);

		return this.post(url, {});
	}

	public setBidOfferConsumptions(
		dto: BiddingClaimSetCostAndConsumptionDto,
		biddingClaimId: string,
	): AxiosPromise<void> {
		const url = this._urlFabric.setBidOfferConsumptions(biddingClaimId);

		return this.post(url, dto);
	}

	public approveSuppliers(dto: SuppliersApproveDto, biddingClaimId: string): AxiosPromise<void> {
		const url = this._urlFabric.approveSuppliers(biddingClaimId);

		return this.post(url, dto);
	}

	public getSuppliers(biddingClaimId: string): AxiosPromise<DictionaryDto<SupplierInBiddingDto>> {
		const url = this._urlFabric.getSuppliers(biddingClaimId);

		return this.get(url);
	}

	public getBiddingClaimOrders(biddingClaimId: string, parameters: any): AxiosPromise<TableDto<OrderTableItemDto>> {
		const url = this._urlFabric.getBiddingClaimOrders(biddingClaimId);

		return this.list(url, parameters);
	}

	public getBiddingClaimSchedule(biddingClaimId: string): AxiosPromise<BiddingClaimGroupingScheduleItemsDto> {
		const url = this._urlFabric.getBiddingClaimSchedule(biddingClaimId);

		return this.get(url);
	}

	public initiationCreate(dto: InitiationCreateBiddingClaimDto): AxiosPromise<void> {
		const url = this._urlFabric.initiationCreate();

		return this.post(url, dto);
	}
}
