import { Either, left, right } from '@sweet-monads/either';
import { AxiosPromise, AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { useInject } from '../../hooks/useInject';
import { NotificationService } from '../../services/notifcation/notificationService';
import { ErrorFormatter } from '../formatters/ErrorFormatter';
import { translate } from '../translate';
import { AuthUserApi } from './../../api/AuthUserApi';
import { Types } from './../../inversify/types';
import { LOGOUT_PAGE } from './../../router/Routes';
import { AuthService } from './../../services/auth/AuthService';
import history from './HistoryHelper';

export interface IApiRequestOptions {
	customErrorHandling: boolean;
}

@injectable()
export class ApiHelper {
	private readonly _notificationService: NotificationService;
	private readonly _authService: AuthService;
	private readonly _authUserApi: AuthUserApi;

	public constructor() {
		this._notificationService = useInject(Types.NotificationService);
		this._authService = useInject(Types.AuthService);
		this._authUserApi = useInject(Types.AuthUserApi);
	}

	public doApiRequest = async <TBody, TError = any>(
		apiRequest: AxiosPromise<TBody>,
		options?: IApiRequestOptions,
	): Promise<Either<AxiosResponse<TError>, TBody>> => {
		try {
			const response = await apiRequest;

			return right(response.data);
		} catch (e) {
			if (!options?.customErrorHandling) {
				this.createError(e.response);
			}

			return left(e.response);
		}
	};

	private _refreshToken = async (refreshToken: string): Promise<void> => {
		try {
			const response = await this._authUserApi.refreshToken(refreshToken);

			this._authService.setUserData(response.data);

			this.setTimeoutRefreshTokenUpdate();
		} catch (e) {
			this._onAuthFailed();
		}
	};

	private _onAuthFailed = (): void => {
		if (!this._authService.getAccessToken()) {
			return;
		}

		this._authService.setLastAuthorisedPath(history.location.pathname);

		history.push(LOGOUT_PAGE);
	};

	public setTimeoutRefreshTokenUpdate = (): void => {
		const refreshToken = this._authService.getRefreshToken();
		const timeWhenRefresh = this._authService.getExpiresIn() - Date.now();

		if (refreshToken && timeWhenRefresh) {
			setTimeout(() => {
				this._refreshToken(refreshToken);
			}, timeWhenRefresh);
		}
	};

	public createError = (res?: AxiosResponse): void => {
		if (!res) {
			this._notificationService.createNotification(
				NotificationService.errorNotificationType,
				translate('notification.serverunavailable'),
			);
			return;
		}

		switch (res.status) {
			case 400: {
				this._notificationService.createNotification(
					NotificationService.errorNotificationType,
					ErrorFormatter.format400Message(res.data),
				);
				break;
			}
			case 401: {
				this._notificationService.createNotification(
					NotificationService.errorNotificationType,
					translate('notification.401error'),
				);
				this._onAuthFailed();
				break;
			}
			case 403: {
				this._notificationService.createNotification(
					NotificationService.errorNotificationType,
					translate('notification.403error'),
				);
				break;
			}
			case 404: {
				this._notificationService.createNotification(
					NotificationService.errorNotificationType,
					translate('notification.404error'),
				);
				break;
			}
			case 500: {
				this._notificationService.createNotification(
					NotificationService.errorNotificationType,
					translate('notification.500error'),
				);
				break;
			}

			default: {
				this._notificationService.createNotification(
					NotificationService.errorNotificationType,
					translate('notification.unknownerror'),
				);
				break;
			}
		}
	};
}
