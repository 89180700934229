type IRouteWithIdOptions = {
	routeUrl: string;
	id?: string;
};

export type IRouteId = {
	name: string;
	optional?: boolean;
};

type IRouteWithIdsOptions = {
	routeUrl: string;
	ids: string[];
};

type IRouteWithIdsNamesOptions = {
	routeUrl: string;
	ids: IRouteId[];
};

export class RouteHelper {
	public static generateRouteWithId = (options: IRouteWithIdOptions): string => {
		const id = options.id ? `/${options.id}` : '/:id';

		return `${options.routeUrl}${id}`;
	};

	public static generateRouteWithIds = (options: IRouteWithIdsOptions): string => {
		const ids = options.ids.map((item) => `${item}`).join('');

		return `${options.routeUrl}/${ids}`;
	};

	public static generateRouteWithIdsNames = (options: IRouteWithIdsNamesOptions): string => {
		const ids = options.ids.map((item) => `:${item.name}${item.optional ? '?' : ''}`).join('');

		return `${options.routeUrl}/${ids}`;
	};

	public static generateListRoute = (routeUrl: string): string => {
		return `${routeUrl}`;
	};

	public static generateCreateRoute = (routeUrl: string): string => {
		return `${routeUrl}/create`;
	};

	public static generateViewRoute = (routeUrl: string): string => {
		return `${routeUrl}/view`;
	};

	public static generateEditorRoute = (routeUrl: string): string => {
		return `${routeUrl}/editor`;
	};
}
