import { EBidEmergencyWorkflowState } from '../../dto/enum/EBidEmergencyWorkflowState';
import { EUserRole } from '../../dto/enum/EUserRole';
import { AccessRuleService } from './AccessRuleService';

export class BidEmergencyAccessRuleService extends AccessRuleService {
	protected _canViewRoles = [
		EUserRole.VolmaEmployee,
		EUserRole.VolmaLeadEngineer,
		EUserRole.VolmaProductionDirector,
		EUserRole.VolmaPurchasingManager,
		EUserRole.VolmaMaterialSupport,
	];
	protected _canCreateRoles = [
		EUserRole.VolmaEmployee,
		EUserRole.VolmaLeadEngineer,
		EUserRole.VolmaProductionDirector,
		EUserRole.VolmaPurchasingManager,
		EUserRole.VolmaMaterialSupport,
	];
	protected _canDeleteRoles = [];
	protected _canEditRoles = [
		EUserRole.VolmaLeadEngineer,
		EUserRole.VolmaProductionDirector,
		EUserRole.VolmaEmployee,
		EUserRole.VolmaPurchasingManager,
		EUserRole.VolmaMaterialSupport,
	];
	protected readonly _canAgreeByDirectorRoles = [EUserRole.VolmaProductionDirector];
	protected readonly _canAgreeByEngineerRoles = [EUserRole.VolmaLeadEngineer];
	protected readonly _canCreateBiddingClaim = [
		EUserRole.VolmaMaterialSupport,
		EUserRole.VolmaAdministrator,
		EUserRole.VolmaPurchasingManager,
	];
	protected _canViewStateHistoryRoles = [
		EUserRole.VolmaEmployee,
		EUserRole.VolmaMaterialSupport,
		EUserRole.VolmaLeadEngineer,
		EUserRole.VolmaProductionDirector,
		EUserRole.VolmaEconomist,
		EUserRole.VolmaAdministrator,
		EUserRole.VolmaPurchasingManager,
	];

	public canRevise = (state: EBidEmergencyWorkflowState | undefined): boolean => {
		let reviseRoles: EUserRole[] = [];

		if (state === EBidEmergencyWorkflowState.New) {
			reviseRoles = [EUserRole.VolmaLeadEngineer];
		} else if (state === EBidEmergencyWorkflowState.ApprovedByEngineer) {
			reviseRoles = [EUserRole.VolmaProductionDirector];
		}

		return this._hasRole(reviseRoles);
	};

	public canAgreeByDirector = (): boolean => {
		return this._hasRole(this._canAgreeByDirectorRoles);
	};

	public canAgreeByEngineer = (): boolean => {
		return this._hasRole(this._canAgreeByEngineerRoles);
	};

	public canCreateBiddingClaim = (): boolean => {
		return this._hasRole(this._canCreateBiddingClaim);
	};

	public canViewHistoryStateTable = (): boolean => {
		return this._hasRole(this._canViewStateHistoryRoles);
	};
}
