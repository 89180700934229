import { injectable } from 'inversify';
import { LocaleObject, setLocale } from 'yup';
import { ValidationMessageFormatter } from '../../utils/formatters/ValidationMessageFormatter';
import { translate } from '../../utils/translate';

@injectable()
export class ValidationService {
	private readonly _locale: LocaleObject = {
		mixed: {
			required: translate('validation.required'),
			default: translate('validation.invalid'),
			oneOf: translate('validation.oneof'),
			notOneOf: translate('validation.notoneof'),
			notType: translate('validation.nottype'),
		},
		number: {
			max: ({ max }) => ValidationMessageFormatter.getNumberMessage('validation.number.max', max),
			min: ({ min }) => ValidationMessageFormatter.getNumberMessage('validation.number.min', min),
			moreThan: ({ value }) => ValidationMessageFormatter.getNumberMessage('validation.number.morethan', value),
			lessThan: ({ value }) => ValidationMessageFormatter.getNumberMessage('validation.number.lessthan', value),
			positive: translate('validation.number.positive'),
			negative: translate('validation.number.negative'),
			integer: translate('validation.number.integer'),
		},
		string: {
			email: translate('validation.invalid.email'),
			matches: translate('validation.invalid'),
			length: ({ length }) => ValidationMessageFormatter.getStringMessage('validation.string.length', length),
			max: ({ max }) => ValidationMessageFormatter.getStringMessage('validation.string.max', max),
			min: ({ min }) => ValidationMessageFormatter.getStringMessage('validation.string.min', min),
		},
		date: {
			max: ({ max }) => ValidationMessageFormatter.getDateMessage('validation.date.max', max),
			min: ({ min }) => ValidationMessageFormatter.getDateMessage('validation.date.min', min),
		},
		array: {
			max: ({ max }) => ValidationMessageFormatter.getArrayMessage('validation.array.max', max),
			min: ({ min }) => ValidationMessageFormatter.getArrayMessage('validation.array.min', min),
		},
		object: {
			noUnknown: translate('validation.invalid'),
		},
	};

	public setLocale(): void {
		setLocale(this._locale);
	}
}
