import { EUserRole } from './../../dto/enum/EUserRole';
import { AccessRuleService } from './AccessRuleService';

export class ReportAccessRuleService extends AccessRuleService {
	protected _canViewRoles = [];
	protected _canCreateRoles = [];
	protected _canDeleteRoles = [];
	protected _canEditRoles = [];
	protected readonly _canCreatePaymentObligationReport = [
		EUserRole.VolmaMaterialSupport,
		EUserRole.VolmaLeadEngineer,
		EUserRole.VolmaProductionDirector,
		EUserRole.VolmaEconomist,
		EUserRole.VolmaAdministrator,
		EUserRole.VolmaPurchasingManager,
	];
	protected readonly _canCreateBiddingClaimJournalReport = [
		EUserRole.VolmaMaterialSupport,
		EUserRole.VolmaAdministrator,
		EUserRole.VolmaTenderOfficer,
		EUserRole.VolmaPurchaseServiceManager,
		EUserRole.VolmaFinancialControlOfficer,
		EUserRole.VolmaEconomicProtectionOfficer,
	];
	protected readonly _canCreateBiddingClaimOffersReport = [
		EUserRole.VolmaAdministrator,
		EUserRole.VolmaPurchasingManager,
		EUserRole.VolmaPurchaseServiceManager,
		EUserRole.VolmaEconomicProtectionOfficer,
		EUserRole.VolmaMaterialSupport,
	];
	protected readonly _canCreateDeliveriesReport = [
		EUserRole.VolmaMaterialSupport,
		EUserRole.VolmaLeadEngineer,
		EUserRole.VolmaProductionDirector,
		EUserRole.VolmaEconomist,
		EUserRole.VolmaAdministrator,
		EUserRole.VolmaPurchasingManager,
		EUserRole.VolmaLogist,
		EUserRole.VolmaTenderOfficer,
		EUserRole.VolmaPurchaseServiceManager,
		EUserRole.VolmaFinancialControlOfficer,
		EUserRole.VolmaEconomicProtectionOfficer,
		EUserRole.CustomerContactPerson,
		EUserRole.CustomerEmployee,
	];
	protected readonly _canCreateNeedsReport = [
		EUserRole.VolmaMaterialSupport,
		EUserRole.VolmaLeadEngineer,
		EUserRole.VolmaProductionDirector,
		EUserRole.VolmaEconomist,
		EUserRole.VolmaAdministrator,
		EUserRole.VolmaPurchasingManager,
		EUserRole.VolmaLogist,
		EUserRole.VolmaTenderOfficer,
		EUserRole.VolmaPurchaseServiceManager,
		EUserRole.VolmaFinancialControlOfficer,
		EUserRole.VolmaEconomicProtectionOfficer,
	];

	public canCreatePaymentObligationReport = (): boolean => {
		return this._hasRole(this._canCreatePaymentObligationReport);
	};

	public canCreateBiddingClaimJournalReport = (): boolean => {
		return this._hasRole(this._canCreateBiddingClaimJournalReport);
	};

	public canCreateBiddingClaimOffersReport = (): boolean => {
		return this._hasRole(this._canCreateBiddingClaimOffersReport);
	};

	public canCreateDeliveriesReport = (): boolean => {
		return this._hasRole(this._canCreateDeliveriesReport);
	};

	public canCreateNeedsReport = (): boolean => {
		return this._hasRole(this._canCreateNeedsReport);
	};
}
