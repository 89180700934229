import { useInject } from '../../../hooks/useInject';
import { Types } from '../../../inversify/types';
import { StringComparer } from '../../../utils/comparers/StringComparer';
import { UserAccessRuleService } from '../../accessRule/UserAccessRuleService';
import { BaseApprover } from '../BaseApprover';

export class UserApprover extends BaseApprover {
	private readonly _userAccessRuleService = useInject<UserAccessRuleService>(Types.UserAccessRuleService);

	public canDeleteUser = (deletableUserId: string | undefined, storedUserId: string | undefined): boolean => {
		const hasAcceptableRole = this._userAccessRuleService.canDelete();
		const isEqualUserIds = StringComparer.compare(deletableUserId, storedUserId);

		return hasAcceptableRole && !isEqualUserIds;
	};
}
