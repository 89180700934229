import { EUserRole } from '../../dto/enum/EUserRole';
import { AccessRuleService } from './AccessRuleService';

export class FinancialPositionLimitAccessRuleService extends AccessRuleService {
	protected _canViewRoles = [
		this.roleService.getAllRolesMaskExcluding([
			EUserRole.VolmaLogist,
			EUserRole.CustomerContactPerson,
			EUserRole.CustomerEmployee,
			EUserRole.VolmaService,
		]),
	];
	protected _canCreateRoles = [EUserRole.VolmaEconomist, EUserRole.VolmaAdministrator];
	protected _canDeleteRoles = [];
	protected _canEditRoles = [EUserRole.VolmaEconomist, EUserRole.VolmaAdministrator, EUserRole.VolmaLeadEngineer];
}
